import { createRouter, createWebHashHistory } from 'vue-router'
import queryString from 'query-string';
import { isUserAuthenticated, removeToken, setReturnUrl, getReturnUrl, removeReturnUrl, getDecodedToken, generateAccess, generateRole } from '@/utils/auth'

import { icons } from '@/utils/icons';
import ActivityBasedTimer from './lib/activityBasedTimer';

import Login from './views/auth/login.vue'
import Register from './views/auth/register.vue'
import Enter from './views/auth/enter.vue'
import Ohayo from './views/auth/ohayo.vue'
import Unauthorized from './views/auth/unauthorized.vue'

import { useVersionStore } from '@/stores/Version';
import { useGlobalState } from '@/stores/GlobalState';

const supplierRoute = (route) => `/hi${route}`;

const routes = [
  {
    path: '/Login',
    component: Login,
    name: 'login',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },
  {
    path: '/Enter/:hash/:token',
    component: Enter,
    name: 'enter',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },
  {
    path: '/ohayo/:tenant/:hash/:token',
    component: Ohayo,
    name: 'ohayo',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },
  {
    path: '/unauthorized',
    component: Unauthorized,
    name: 'unauthorized',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },
  {
    path: '/Register',
    component: Register,
    name: 'register',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },

  {
    path: '/scratch-my-back',
    component: () => import('./views/_dev/Scratch.vue'),
    name: 'scratch-my-back',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true }),
      isDev: true
    },
  },

  {
    path: '/',
    component: () => import('./views/buyers/dashboards/Home.vue'),
    name: 'buyer-home',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true }),
    },
  },
  {
    path: '/create-package/:templateId?/project/:projectId?',
    component: () => import('./views/buyers/dashboards/packs/Create.vue'),
    props: true,
    name: 'buyer-create-package',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true }),
    },
  },
  {
    path: '/projects/:id',
    component: () => import('./views/buyers/dashboards/projects/Work.vue'),
    name: 'buyer-manage-project',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true }),
    },
  },
  {
    path: '/packages/:id',
    component: () => import('./views/buyers/dashboards/packs/Work.vue'),
    name: 'buyer-manage-package',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true })
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('./views/buyers/dashboards/Projects.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'nav.projects',
      icon: icons.project,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
  },
  {
    path: '/pos',
    name: 'purchase-orders',
    component: () => import('./views/buyers/dashboards/pos/List.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'nav.purchaseOrders',
      icon: icons.purchaseOrder,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
  },

  {
    path: '/pos/:id/work',
    name: 'purchase-orders-work',
    component: () => import('./views/buyers/dashboards/pos/Work.vue'),
    meta: {
      requiresAuth: true,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
  },  


  {
    path: '/ai',
    name: 'ai',
    component: () => import('./views/buyers/ai/Layout.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'nav.ai',
      icon: icons.automations,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
    children: [
      {
        path: '/ai/automations',
        name: 'automations',
        component: () => import('./views/buyers/ai/agents/ItemsDashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'nav.automations',
          icon: icons.agent,
          access: generateAccess({ admin: true }),
          role: generateRole({ buyer: true })
        },
      },
      {
        path: '/ai/sources',
        name: 'sources',
        component: () => import('./views/buyers/ai/sources/ItemsDashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'nav.sources',
          icon: icons.data,
          access: generateAccess({ admin: true }),
          role: generateRole({ buyer: true })
        },
      },
    ]
  },  

  {
    path: '/templates',
    name: 'templates',
    component: () => import('./views/buyers/templates/Layout.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'nav.templates',
      icon: icons.template,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
    children: [
      {
        path: '/templates/rfx',
        name: 'package-templates',
        component: () => import('./views/buyers/templates/packs/ItemsDashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'nav.rfx',
          icon: icons.templates,
          access: generateAccess({ admin: true }),
          role: generateRole({ buyer: true })
        },
      },
      {
        path: '/templates/catalogue',
        name: 'requirement-templates',
        component: () => import('./views/buyers/templates/reqs/ItemsDashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'nav.catalogue',
          icon: icons.input,
          access: generateAccess({ admin: true }),
          role: generateRole({ buyer: true })
        },
      },
    ]
  },

  {
    path: '/settings',
    name: 'settings',
    component: () => import('./views/buyers/settings/Layout.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'nav.settings',
      icon: icons.settings,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
    children: [
      {
        path: '/settings/vendors',
        name: 'vendors',
        component: () => import('./views/buyers/settings/vendors/ItemsDashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'nav.vendors',
          icon: icons.vendors,
          access: generateAccess({ admin: true }),
          role: generateRole({ buyer: true })
        },
      },
      {
        path: '/settings/users',
        name: 'users',
        component: () => import('./views/buyers/settings/users/ItemsDashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'nav.users',
          icon: icons.users,
          access: generateAccess({ admin: true }),
          role: generateRole({ buyer: true })
        },
      },
    ]
  },

  {
    path: supplierRoute(""),
    component: () => import('./views/vendors/dashboards/Home.vue'),
    name: 'vendor-home',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ vendor: true })
    },
  },
  {
    path: supplierRoute("/pos"),
    component: () => import('./views/vendors/dashboards/pos/List.vue'),
    name: 'vendor-pos',
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'nav.purchaseOrders',
      icon: icons.purchaseOrder,      
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ vendor: true })
    },
  },
  {
    path: supplierRoute("/pos/:id/work"),
    component: () => import('./views/vendors/dashboards/pos/Work.vue'),
    name: 'vendor-work-pos',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ vendor: true })
    },
  },    
  {
    path: supplierRoute("/packages/:id/work"),
    component: () => import('./views/vendors/dashboards/packs/Work.vue'),
    name: 'vendor-work-package',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ vendor: true })
    },
  },
  {
    path: supplierRoute('/users'),
    name: 'vendor-users',
    component: () => import('./views/vendors/settings/users/ItemsDashboard.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'nav.users',
      icon: icons.users,
      access: generateAccess({ admin: true }),
      role: generateRole({ vendor: true })
    },
  },
  {
    path: supplierRoute('/sources'),
    name: 'vendor-sources',
    component: () => import('./views/vendors/settings/sources/ItemsDashboard.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'nav.sources',
      icon: icons.data,
      access: generateAccess({ admin: true }),
      role: generateRole({ vendor: true })
    },
  },  
  { path: '/:pathMatch(.*)*', redirect: '/' }
];

const versioningTimer = ActivityBasedTimer();

versioningTimer.setInterval({
  async callback() {
    const versionManager = useVersionStore();
    versionManager.checkVersion()
  },
  // Normal interval is once every 1 minute
  interval: 1000 * 60 * 1,
  // Forced interval is every 10 minutes
  forcedInterval: 1000 * 60 * 10,
});

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
});

router.beforeEach((to, from) => {

  try {
    versioningTimer.runTimersCheck();

    const { requiresAuth, access: navAccess, role: navRole, isDev } = to.meta;
    const isAuthenticated = isUserAuthenticated();
    const returnUrl = getReturnUrl();

    if (to.query?.ReturnUrl) {
      setReturnUrl(to.query?.ReturnUrl);
    }

    if (isAuthenticated) {

      if (isDev === true && process.env.NODE_ENV !== 'development') {
        return { name: 'unauthorized' };
      }

      const { access: tokenAccess, role: tokenRole } = getDecodedToken();

      const hasNoRoles = !tokenRole || navRole.length === 0;
      const roleNotMatched = navRole && navRole.length > 0 && !navRole.some(r => tokenRole.includes(r));

      if ((hasNoRoles || roleNotMatched) && to.name !== 'unauthorized') {
        return { name: 'unauthorized' };
      }

      const userAccess = tokenAccess.split(',').map(a => a.trim());
      const hasNoAccess = !tokenAccess || userAccess.length === 0;
      const accessNotMatched = navAccess && navAccess.length > 0 && !navAccess.some(a => userAccess.includes(a));

      if ((hasNoAccess || accessNotMatched) && to.name !== 'unauthorized') {
        return { name: 'unauthorized' };
      }

      useGlobalState().fetchLookups();

      if (returnUrl) {

        removeReturnUrl();
        const [path, q] = returnUrl.split('?');
        const query = q ? queryString.parse(q) : {};

        if (to.path !== path) {
          return { path: path, query: query };
        }
      }
    } else {
      if (requiresAuth && to.name !== 'login') {
        removeToken();

        if (from.fullPath !== to.fullPath) {
          return { name: 'login', query: { ReturnUrl: to.fullPath } };
        } else {
          return { name: 'login' };
        }
      }
    }

  } catch (error) {
    console.error(error);

    if (to.name !== 'login') {

      removeToken();
      return { name: 'login' };
    }
  }
});



export default router