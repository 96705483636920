export const es = {
    common: {
        alinorDigitalWorkers: 'Trabajador Digital de Alinor',
        alwaysOn: 'Siempre Encendido',
        copyEmail: 'Copiar Correo Electrónico',
        firstName: 'Nombre',
        lastName: 'Apellido',
        email: 'Correo Electrónico',
        contactPerson: 'Persona de Contacto',
        contactEmail: 'Correo Electrónico de Contacto',
        cancel: 'Cancelar',
        noData: 'Nada aquí todavía',
        noDataDescription: 'No hay datos para mostrar aquí todavía.',
        noResultsFound: 'No se encontraron resultados',
        create: 'Crear',
        save: 'Guardar',
        submit: 'Enviar',
        send: 'Enviar',
        createNew: 'Crear nuevo',
        actions: 'Acciones',
        edit: 'Editar',
        delete: 'Eliminar',
        manage: 'Gestionar',
        next: 'Siguiente',
        previous: 'Anterior',
        limit: 'Límite',
        name: 'Nombre',
        description: 'Descripción',
        add: 'Agregar',
        data: 'Datos',
        generate: 'Generar',
        link: 'Enlace',
        type: 'Tipo',
        details: 'Detalles',
        description: 'Descripción',
        project: 'Proyecto',
        requirement: 'Requisito',
        areYouSure: '¿Estás seguro?',
        replace: 'Reemplazar',
        replaceContext: 'Reemplazar contexto',
        replaceOriginal: 'Reemplazar original',
        thisActionCannotBeUndone: 'Esta acción no se puede deshacer.',
        step: 'Paso {step} de {total}',
        collectionXofY: 'Colección {step} de {total}',
        noResultsFound: 'No se encontraron resultados',
        filterX: 'Filtrar {x}',
        filter: 'Filtrar',
        search: 'Buscar',
        selectX: 'Seleccionar {x}',
        select: 'Seleccionar',
        writeSomething: 'Escribe algo',
        writeComment: 'Escribir comentario',
        fixTheGrammer: 'Corregir la gramática',
        instructions: 'Instrucciones',
        responseTemplate: 'Plantilla de respuesta',
        content: 'Contenido',
        how: '¿Cómo?',
        why: '¿Por qué?',
        references: 'Referencias',
        referencesDescription: 'Agregar documentos, sitios web y otras referencias para apoyar el proyecto.',
        documents: 'Documentos',
        document: 'Documento',
        documentsDescription: 'Generar documentos del proyecto.',
        assignToUser: 'Asignar a persona',
        website: 'Sitio web',
        person: 'Persona',
        people: 'Personas',
        tags: 'Etiquetas',
        information: 'Información',
        noAnswerFound: 'No se encontró respuesta',
        chatWithInformationInThisProject: 'Chatear con información en este proyecto',
        xOutOfYSelected: '{x} de {y} seleccionados',
        xOutOfY: '{x} de {y}',
        download: 'Descargar',
        print: 'Imprimir',
        pdf: 'PDF',
        docx: 'DOCX',
        write: 'Escribir',
        rewrite: 'Reescribir',
        executeYourPrompt: 'Ejecutar tu solicitud',
        adminTitle: '¿Es administrador?',
        admin: 'Administrador',
        adminDescription: 'Los administradores tienen acceso a configuraciones.',
        packages: 'RFXs',
        package: 'RFx',
        catalogue: 'Catálogo',
        packagesDescription: 'Crear un RFx para el proyecto.',
        zipArchive: 'Archivo ZIP',
        content: 'Contenido',
        tasks: 'Tareas',
        tasksDescription: 'Comentar y asignar tareas a los miembros.',
        task: 'Tarea',
        visablity: 'Visibilidad',
        members: 'Miembros',
        settings: 'Configuraciones',
        history: 'Historial',
        changes: 'Cambios',
        elements: 'Elementos',
        element: 'Elemento',
        comments: 'Comentarios',
        comment: 'Comentario',
        analyze: 'Analizar',
        refFiles: 'Archivos de referencia',
        refFilesDescription: 'Agregar archivos de referencia para ser analizados',
        addAnalysisInstructions: 'Agregar instrucciones para el análisis',
        downloadHasStarted: 'La descarga ha comenzado',
        ok: 'Ok',
        close: 'Cerrar',
        showTasks: 'Mostrar tareas',
        sections: 'Secciones',
        sectionsDescription: 'Gestionar secciones del proyecto.',
        template: 'Plantilla',
        value: 'Valor',
        references: 'Referencias',
        reference: 'Referencia',
        extractSections: '¿Extraer secciones?',
        extractSectionsDescription: 'Extraer secciones de una fuente',
        extractContent: '¿Extraer contenido?',
        extractContentDescription: 'Extraer contenido de la fuente',
        projects: 'Proyectos',
        project: 'Proyecto',
        generateInstructions: 'Generar instrucciones',
        extractInstructions: 'Extraer instrucciones',
        readInstructions: 'Leer instrucciones',
        agents: 'Agentes',
        agent: 'Agente',
        templates: 'Plantillas',
        template: 'Plantilla',
        basicInformation: 'Información básica',
        selectSections: 'Seleccionar secciones',
        changeFile: 'Cambiar archivo',
        view: 'Ver',
        hide: 'Ocultar',
        source: 'Fuente',
        extraction: 'Extracción',
        extract: 'Extraer',
        blank: '- En blanco -',
        newDocument: 'Nuevo documento',
        latestDocuments: 'Últimos documentos',
        latestPackages: 'Últimos RFXs',
        latestPurchaseOrders: 'Últimas órdenes de compra',
        version: 'Versión {x}',
        versions: 'Versiones',
        showHistory: 'Mostrar historial',
        addSection: 'Agregar sección',
        marks: 'Marcas',
        focusMode: 'Modo de enfoque',
        listMode: 'Modo de lista',
        section: 'Sección',
        xOfY: '{x} / {y}',
        targetDate: 'Fecha objetivo',
        assignTo: 'Asignar a',
        new: 'Nuevo',
        typeACommandOrSearch: '¿En qué quieres trabajar hoy?',
        yourDocuments: 'Tus documentos',
        yourProjects: 'Tus proyectos',
        tasksThatAreAssignedToYou: 'Tareas asignadas a ti',
        tasksThatYouHaveCreated: 'Tareas que has creado',
        work: 'Trabajo',
        requirements: 'Requisitos',
        requirement: 'Requisito',
        sectionOf: 'Sección {x} de {y}',
        requirementOf: 'Requisito {x} de {y}',
        what: '¿Qué?',
        next: 'Siguiente',
        review: 'Revisar',
        vendors: 'Proveedores',
        businessId: 'Business-ID',
        contacts: 'Contactos',
        contact: 'Contacto',
        sources: 'Fuentes',
        noAnswerFound: 'No se encontró respuesta',
        askAQuestion: 'Haz una pregunta sobre documentos o proyectos',
        splitScreen: 'Pantalla dividida',
        minimize: 'Minimizar',
        pickTemplate: 'Elegir una plantilla',
        supportingReferences: 'Referencias de apoyo',
        specifications: 'Especificaciones',
        addRequirements: 'Agregar requisito',
        createPackage: 'Crear RFXs',
        addSource: 'Agregar fuente',
        priority: 'Prioridad',
        scoreWeight: 'Peso de la puntuación',
        score: 'Puntuación',
        owner: 'Propietario',
        communication: 'Comunicación',
        attachments: 'Adjuntos',
        addAttachment: 'Agregar adjunto',
        extractRequirements: 'Extraer requisitos',
        extractRequirementsDescription: 'Extraer requisitos de una fuente',
        fillInformation: 'Rellenar información',
        addContact: 'Agregar contacto',
        collection: 'Recopilación de datos',
        requirementsAndCritera: 'Requisitos y criterios de evaluación',
        supportingMaterials: 'Materiales de apoyo',
        doYouWantToCollectInformationFromSender: 'Cuando se crea, ¿quieres recopilar información del remitente?',
        doYouWantToCollectInformationFromRReceiver: 'Cuando se envía, ¿quieres recopilar información del receptor?',
        doYouWantToCollectInformationOnOfferSubmission: 'Cuando se envía una oferta, ¿quieres recopilar información?',
        doYouWantToIncludeRequirements: '¿Quieres incluir requisitos estándar?',
        doYouWantToIncludeEvaluationCriteria: '¿Quieres incluir criterios de evaluación?',
        doYouWantToIncludeDocuments: '¿Quieres incluir documentos?',
        doYouWantToIncludeSupportingReferences: '¿Quieres incluir referencias?',
        thisIsRepeated: 'Esto se repite cada vez.',
        theseAreIncludedEveryTime: 'Estos se incluyen cada vez.',
        withAi: 'Con AI',
        WithoutAi: 'Sin AI',
        extractInformation: 'Extraer información',
        extractInformationDescription: 'Extraer información de una fuente',
        whatIsTheType: '¿Cuál es el tipo?',
        howManyDoYouWant: '¿Cuántos quieres?',
        automations: 'Automatizaciones',
        event: 'Evento',
        action: 'Acción',
        when: 'Cuándo',
        do: 'Hacer',
        group: 'Grupo',
        function: 'Función',
        collection: 'Colección',
        many: 'Muchos',
        date: 'Fecha',
        number: 'Número',
        filter: 'Filtrar',
        vendor: 'Proveedor',
        status: 'Estado',
        reset: 'Restablecer',
        message: 'Mensaje',
        timeline: 'Última actividad',
        created: 'Creado',
        target: 'Objetivo',
        invite: 'Agregar',
        inviteVendor: 'Agregar proveedor',
        editIndvidualMessage: 'Editar mensaje individual',
        noDescription: 'Sin descripción',
        noAttachments: 'Sin adjuntos',
        noTasks: 'Sin tareas',
        noValues: 'Sin valores',
        noValue: 'Sin valor',
        copy: 'Copiar',
        or: 'o',
        values: 'Valores',
        addAnother: 'Agregar otro',
        manageSupportingMaterials: 'Gestionar materiales de apoyo',
        dueInDaysFromTrigger: 'Vence en días desde el desencadenante',
        addComment: 'Agregar comentario',
        changeStatus: 'Cambiar estado',
        confirmEmail: 'Confirmar correo electrónico',
        emailsMustMatch: 'Los correos electrónicos deben coincidir',
        createFromScratch: 'Crear desde cero',
        today: 'Hoy',
        switch: 'Cambiar',
        buyer: 'Comprador',
        reject: 'Rechazar',
        accept: 'Aceptar',
        exactly: 'Exactamente',
        deviation: 'Desviación',
        undo: 'Deshacer',
        confirm: 'Confirmar',
        requirements: 'Requisitos',
        rank: 'Rango',
        requirement: 'Requisito',
        duplicate: 'Duplicar',
        inputFromVendor: 'Entrada del proveedor',
        content: 'Contenido',
        noVendorsInvited: 'No se han agregado proveedores',
        informationProvidedToYou: 'Información proporcionada a ti',
        informationYouNeedToProvide: 'Información que necesitas proporcionar',
        deviationTitle: 'Marcar como desviación',
        deviationDescription: 'Indicar una desviación de los requisitos',
        targetDateTitle: 'Indicar una fecha objetivo',
        targetDateDescription: 'Marcar una fecha límite para el comentario',
        viewPreviousComments: 'Ver comentarios anteriores',
        hideComments: 'Ocultar comentarios anteriores',
        deviation: 'Desviación',
        updateSpecificVendorsTitle: 'Actualizar proveedor específico',
        updateSpecificVendorsDescription: 'Actualizar proveedores específicos con nueva información',
        assignToUserTitle: 'Asignar a persona',
        assignToUserDescription: 'Asignar la tarea a una persona específica',
        templateExample: 'Ejemplo: RFx para Servicio o Parte',
        requirementExample: 'Ejemplo: NDA, Alcance del trabajo, Línea de artículo, Requisito técnico',
        requirementCountTitle: '¿Cuántas veces aparece esto?',
        requirementCountDescription: 'Indicar cuántas veces debe aparecer este requisito por plantilla',
        requirementBuyerInputDescription: 'Información que proporcionas al proveedor',
        requirementVendorInputDescription: 'Información que el proveedor te proporciona',
        requirementBuyerInputTitle: '¿Envías información al proveedor?',
        requirementVendorInputTitle: '¿Recibes información del proveedor?',
        commentsAreEnabledByDefault: 'Tú y el proveedor pueden agregar comentarios por defecto',
        addToExitingProject: 'Agregar a proyecto existente',
        doesThisPackageBelongToAProject: '¿Este RFx pertenece a un proyecto?',
        uniqueRef: 'Referencia única',
        addToProject: 'Agregar al proyecto',
        inviteVendors: 'Agregar proveedores',
        whoWouldYouLikeToContact: '¿A quién te gustaría contactar?',
        communcation: 'Comunicación',
        activeProjects: 'Proyectos activos',
        copied: '¡Copiado!',
        copiedToClipboard: 'Copiado al portapapeles',
        existing: 'Existente',
        resolve: 'Resolver',
        reason: 'Ingresa la resolución aquí',
        inputs: 'Entradas',
        dragAndDropInputs: 'Arrastra y suelta entradas aquí',
        setInputSettings: 'Configurar ajustes de entrada',
        overviewMode: 'Modo de vista general',
        total: 'Total',
        packageSubmited: 'Información enviada',
        packageSubmittedDescription: 'La información ha sido enviada y está esperando revisión.',
        commentSubmitted: 'Comentario enviado',
        commentSubmittedDescription: 'El comentario ha sido enviado y el comprador ha sido informado.',
        rejectionSubmitted: 'Rechazo enviado',
        rejectionSubmittedDescription: 'El rechazo ha sido enviado y el comprador ha sido informado.',
        yes: 'Sí',
        no: 'No',
        you: 'Tú',
        evaluation: 'Evaluación',
        evaluationCriteria: 'Criterios de evaluación',
        evaluationRound: 'Ronda de evaluación',
        evaluationRounds: 'Rondas de evaluación',
        evaluationRoundOfX: 'Ronda {x}',
        round: 'Ronda',
        criteria: 'Criterios',
        weight: 'Peso',
        awardPackageToVendor: 'Adjudicar paquete al proveedor',
        awardRequirementsToVendor: 'Adjudicar requisitos al proveedor',
        award: 'Adjudicar',
        note: 'Nota',
        uploadRefDescription: 'Subir referencia para que la AI la use',
        rfxAiDescription: 'Describir lo que debe existir en este RFx',
        criteriaDescription: 'Describir los criterios y pesos para la evaluación',
        optional: 'Opcional',
        useAI: 'Usar AI',
        NA: 'N/A',
        remove: 'Eliminar',
        fillWithAi: 'Rellenar con AI',
        searchWithAi: 'Buscar con AI',
        whatDataDoYouWantToCollect: '¿Qué datos quieres recopilar?',
        changeMic: '¿Cambiar micrófono?',
        changeCam: '¿Cambiar cámara?',
        pause: 'Pausar',
        start: 'Iniciar',
        stop: 'Detener',
        resume: 'Reanudar',
        noMicPermission: 'Sin permiso de micrófono',
        noCamPermission: 'Sin permiso de cámara',
        noScreenPermission: 'Sin permiso de pantalla',
        noMicFound: 'No se encontró micrófono',
        ready: 'Listo',
        recording: 'Grabando',
        paused: 'Pausado',
        done: 'Hecho',
        automations: 'Automatizaciones',
        on: 'Encendido',
        do: 'Hacer',
        trigger: 'Desencadenar',
        action: 'Acción',
        dashboard: 'Tablero',
        myAccount: 'Mi cuenta',
        evaluation: 'Evaluación',
        evaluate: 'Evaluar',
        findInformationInsideThisProject: 'Encontrar información dentro de este RFx',
        negotiation: 'AI de negociación',
        beta: 'Beta',
        supplierPreview: 'Vista previa del proveedor',
        deviations: 'Desviaciones',
        noDeviations: 'Sin desviaciones',
        messages: 'Mensajes',
        sent: 'Enviado',
        assignedTo: 'Asignado a',
        reply: 'Responder',
        globalSearch: 'Búsqueda global',
        inviteMembersToEvaluate: 'Invitar miembros a esta ronda',
        selectAll: 'Seleccionar todo',
        awardToRecommendedVendor: 'Adjudicar al proveedor recomendado {x}',
        awardedAutomatically: 'Adjudicado automáticamente',
        applyAIScore: 'Aplicar puntuación AI',
        readMore: 'Leer más',
        readLess: 'Leer menos',
        awarded: 'Adjudicado',
        inbox: 'Bandeja de entrada',
        messages: 'Mensajes',
        deviations: 'Desviaciones',
        all: 'Todo',
        pending: 'Pendiente',
        resolved: 'Resuelto',
        replyTo: 'Responder a',
        sendTo: 'Enviar a',
        compose: 'Redactar',
        sendOnSpecificRequirement: 'Enviar sobre requisito',
        sendToAllVendors: 'Enviar a todos los proveedores',
        success: 'Éxito',
        currency: 'Moneda',
        country: 'País',
        incoterm: 'Incoterm',
        unit: 'Unidad',
        phone: 'Teléfono',
        address: 'Dirección',
        import: 'Importar',
        users: 'Usuarios',
        headesUp: '¡Atención!',
        update: 'Actualizar',
        newVersionAvailable: 'Hay una nueva versión de Alinor disponible, recarga la página para actualizar.',
        events: 'Eventos',
        system: 'Sistema',
        toolbox: 'Caja de herramientas',
        showInputs: 'Mostrar entradas',
        whatRFxWouldYouLikeToCreate: '¿Qué RFx te gustaría crear?',
        whatAreYouLookingFor: '¿Qué estás buscando?',
        describeTheRFx: 'Describe los requisitos para el RFx y lo que estás buscando.',
        describeAdditionalInformation: 'Describe requisitos adicionales que deben existir.',
        describeAdditionalInformationOrSelect: 'Describe requisitos adicionales que deben existir, o selecciona una plantilla haciendo clic en +.',
        describeEvaluationCriteriaOrSelect: 'Describe los criterios de evaluación y pesos para el RFx, o selecciona un proveedor de la lista.',
        describeEvaluationCriteriaOrAdd: 'Describe los criterios de evaluación y pesos para el RFx, o agrega manualmente haciendo clic en +.',
        describeWhatToImport: 'Describe lo que se debe importar de la fuente.',
        describeAddtionalInputs: 'Describe entradas adicionales que deben existir, o haz clic en + para agregar usando arrastrar y soltar.',
        addFileReference: 'Agregar referencia de archivo',
        addWebsiteReference: 'Agregar referencia web',
        addSourceReference: 'Agregar referencia de fuente',
        files: 'Archivos',
        links: 'Enlaces',
        workers: 'Trabajadores',
        sources: 'Fuentes',
        build: 'Construir',
        find: 'Encontrar',
        purchaseOrder: 'Orden de compra',
        scan: 'Escanear',
        issueDate: 'Fecha de emisión',
        dueDate: 'Fecha de vencimiento',
        subTotal: 'Subtotal',
        tax: 'Impuesto',
        shipping: 'Envío',
        total: 'Total',
        terms: 'Términos',
        notes: 'Notas',
        required: '¿Requerido?',
        vendorMessage: 'Mensaje predeterminado del proveedor utilizado al enviar el RFx',
        sendResponse: 'Enviar respuesta',
        confirmDateAndDelivery: 'Confirmar fecha y lugar de entrega',
        pendingConfirmation: 'Pendiente de confirmación sobre la fecha y lugar de entrega',
        proposeANewDate: 'Proponer una nueva fecha',
        poConfirmMessage: 'Confirmamos que podemos entregar los bienes en el lugar y día solicitados.',
        poPendingMessage: 'Necesitamos verificar si podemos entregar en el lugar y hora solicitados.',
        poProposeMessage: 'No podemos entregar en la fecha y hora solicitadas.',
        proposedDate: 'Fecha propuesta',
        failedToSave: 'Error al guardar',
        dropFileHere: 'Suelta el archivo aquí',
    },
    settings: {
        settings: 'Configuraciones',
        settingsDescription: 'Administrar las configuraciones de su organización.',
        templates: 'Plantillas',
        templatesDescription: 'Administrar requisitos reutilizables.',
        projects: 'Proyectos',
        packages: 'RFXs',
        documents: 'Documentos',
        requirements: 'Requisitos',
        evaluationCriteria: 'Criterios de evaluación',
        references: 'Referencias',
        agents: 'Agentes',
        vendors: 'Proveedores',
        users: 'Usuarios',
        automations: 'Automatizaciones',
    },
    wizards: {
        createPackage: {
            basicInformation: 'Información básica',
            basicInformationDescription: 'Complete la información básica para el RFx.',
            requirements: 'Requisitos',
            requirementsDescription: 'Agregue requisitos al RFx.',
            vendors: 'Proveedores',
            vendorsDescription: 'Enviar el RFx a los proveedores.',
        },
        evaluation: {
            criteria: 'Criterios',
            criteriaDescription: 'Agregue criterios de evaluación.',
            vendors: 'Proveedores',
            vendorsDescription: 'Seleccione proveedores para la ronda.',
            evaluate: 'Evaluar',
            evaluateDescription: 'Evaluar la ronda.',
        },
        sections: {
            settings: {
                title: 'Configuraciones',
                description: 'Administrar usuarios y proveedores.',
            },
            templates: {
                title: 'Plantillas',
                description: 'Administrar componentes reutilizables.',
            },
            ai: {
                title: 'AI',
                description: 'Administrar configuraciones de AI.',
            }
        }
    },
    editor: {
        bold: 'Negrita',
        italic: 'Cursiva',
        strike: 'Tachado',
        code: 'Código',
        highlight: 'Resaltar',
        h1: 'Encabezado 1',
        h2: 'Encabezado 2',
        paragraph: 'Párrafo',
        bulletList: 'Lista de viñetas',
        orderedList: 'Lista ordenada',
        taskList: 'Lista de tareas',
        codeBlock: 'Bloque de código',
        blockQuote: 'Cita en bloque',
        horizontalRule: 'Regla horizontal',
        hardBreak: 'Salto de línea',
        clearFormatting: 'Borrar formato',
        undo: 'Deshacer',
        redo: 'Rehacer',
        words: '{words} Palabras ',
        characters: '{characters} Caracteres',
    },
    nav: {
        logout: 'Cerrar sesión',
        projects: 'Proyectos',
        purchaseOrders: 'Órdenes de compra',
        ai: 'AI',
        agents: 'Agentes',
        sources: 'Fuentes',
        templates: 'Plantillas',
        rfx: 'RFXs',
        catalogue: 'Catálogo',
        settings: 'Configuraciones',
        vendors: 'Proveedores',
        users: 'Usuarios',       
    },
    views: {
        auth: {
            enter: {
                loggingIn: 'Iniciando sesión...',
                hangTight: '¡Espera!',
                welcome: 'Bienvenido'
            },
            unauthorized: {
                title: 'No autorizado',
                description: 'No tienes autorización para acceder a esta página.',
                goBack: 'Volver',
            },
            login: {
                magicLink: 'Te hemos enviado un enlace mágico a tu correo electrónico',
                checkYourInbox: 'Revisa tu bandeja de entrada',
                signIn: 'Iniciar sesión',
                enterYourEmail: 'Ingresa tu correo electrónico a continuación para iniciar sesión en tu cuenta',
            },
            register: {
                organization: 'Nombre de la organización',
                businessId: 'Business-ID',
                magicLink: 'Te hemos enviado un enlace mágico a tu correo electrónico',
                checkYourInbox: 'Revisa tu bandeja de entrada',
                register: 'Registrarse',
                start: 'Comenzar',
                yourAccountWillbeValidatedByAlinor: 'Tu cuenta será validada por Alinor',
            }
        },
    },
    validation: {
        nameIsRequired: 'Nombre es requerido',
        rfXNameIsRequired: 'Nombre de RFx es requerido',
        componentNameIsRequired: 'Nombre de requisito es requerido',
        componentValuesIsRequired: 'Valores de requisito son requeridos',
        targetDateIsRequired: 'Fecha objetivo es requerida',
        targetDateIsRequired: 'Fecha objetivo es requerida',
        dueDateIsRequired: 'Fecha de vencimiento es requerida',
        issueDateIsRequired: 'Fecha de emisión es requerida',
        poNumberIsRequired: 'Número de orden de compra es requerido',
        vendorIsRequired: 'Proveedor es requerido',
    },
    languages: {
        en: 'English',
        es: 'Español',
    }
}