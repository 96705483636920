<script setup>

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  modelValue: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['update:modelValue']);

const updateValue = (value) => {
  emits('update:modelValue', value);
}

</script>

<template>
  <div class="min-w-fit">
    <TagsInput @update:modelValue="v => updateValue(v)" :addOnPaste="true" :duplicate="false" delimiter=",">
      <TagsInputItem v-if="props.modelValue" v-for="(item, index) in props.modelValue" :key="index" :value="item">
        <TagsInputItemText />
        <TagsInputItemDelete />
      </TagsInputItem>
  
      <TagsInputInput :placeholder="props.title" />
    </TagsInput>
  </div>
</template>