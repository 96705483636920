<script setup>

import { ref, computed, watch } from 'vue';
import { useGlobalState } from '@/stores/GlobalState';
import { useI18n } from 'vue-i18n'
import { useDropZone } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';
import { icons } from '@/utils/icons';

import { addRef } from '@/lib/utils';

const store = useGlobalState();
const { t } = useI18n();

const props = defineProps({
    value: Object,
    element: Object,
    hideLabel: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    }
});

const dropZoneRef = ref(null);

watch(() => dropZoneRef.value, (value) => {
    if (value) {
        useDropZone(dropZoneRef, {
            onDrop,
            multiple: false,
            preventDefaultForUnhandled: false,
        })
    }
});

const fileData = ref(null);
const randomInputName = computed(() => `ref-input-${uuidv4()}`);

const onDrop = (files) => {
    if (props.readonly) {
        return;
    }

    if (!files || files.length === 0) {
        return;
    }

    const f = files[0];
    if (!f) {
        return;
    }

    fileData.value = {
        name: f.name,
        size: f.size,
        lastModified: f.lastModified,
    };
    props.value.file = f;
}

const getFileSizeFromBytes = (file) => {
    if (!file) {
        return '';
    }

    const size = file.size;
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
}

const getLastModified = (file) => {
    if (!file) {
        return '';
    }

    return new Date(file.lastModified).toISOString().replace('T', ' ').replace(/\..+/, '');
}

const trimFileName = (file) => {
    if (!file) {
        return '';
    }

    const max = 20;
    const name = file.name;

    const ext = name.split('.').pop();
    const fileName = name.split('.').slice(0, -1).join('.');

    if (fileName.length <= max) {
        return name;
    } else {
        return `${fileName.slice(0, max)}...${ext}`;
    }
}

const trimName = (name) => {
    if (!name) {
        return '';
    }

    const max = 20;

    if (name.length <= max) {
        return name;
    } else {
        return `${name.slice(0, max)}...`;
    }
}

const dropClicked = () => {
    if (props.readonly) {
        return;
    }

    addRef(randomInputName.value);
}

const removeFile = () => {
    fileData.value = null;
    props.value.file = null;
}

</script>

<template>
    <div class="flex flex-col items-start gap-1.5">
        <div v-if="!hideLabel" class="flex flex-row items-center gap-1">
            <template v-if="$slots.label">
                <slot name="label"></slot>
            </template>
            <Label :for="`${element.id}-${element.uid}`" class="text-sm">{{ element.name }}</Label>
        </div>

        <Input v-if="element.type === 0" :id="`${element.id}-${element.uid}`" class="bg-white" type="text" :placeholder="element.name" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Textarea v-else-if="element.type === 1" :id="`${element.id}-${element.uid}`" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" class="w-full h-24" :placeholder="element.name"></Textarea>

        <Number v-else-if="element.type === 2" :title="element.name" :modelValue="parseFloat(value.value)" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

        <DatePicker v-else-if="element.type === 3" :title="element.name" :modelValue="value.value" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

        <div v-if="element.type === 4 || element.type === 15" class="w-full flex flex-col">
            <div v-if="props.readonly">
                <span>{{ trimName(value?.value) }}</span>
            </div>
            <div v-else class="flex flex-col w-full gap-2">
                <div ref="dropZoneRef" class="flex flex-col w-full min-h-[100px] h-auto bg-gray-400/10 justify-center items-center rounded-md cursor-pointer hover:bg-gray-400/20" @click.stop="dropClicked">
                    <Transition mode="out-in">
                        <div v-if="fileData" class="flex flex-col justify-center items-center gap-2 text-sm p-4">
                            <span>{{ trimFileName(fileData) }}</span>
                            <span>{{ getFileSizeFromBytes(fileData) }}</span>
                            <span>{{ getLastModified(fileData) }}</span>
                        </div>
                        <div v-else class="flex flex-col justify-center items-center gap-2 text-sm p-4">
                            <span>
                                {{ t('common.dropFileHere') }}
                            </span>
                            <span>
                                <Icon :icon="icons.upload" class="shrink-0" />
                            </span>
                        </div>
                    </Transition>
                    <input :id="randomInputName" type="file" multiple class="hidden" @change="e => onDrop(e.target.files)" @blur="e => onDrop(e.target.files)" @reset="e => onDrop(e.target.files)" />
                </div>
                <Transition mode="out-in">
                    <div v-if="fileData">
                        <Button variant="outline" size="sm" class="w-full text-destructive" @click="removeFile">
                            <span class="mr-2">
                                {{ t('common.remove') }}
                            </span>
                            <Icon :icon="icons.trash" class="shrink-0" />
                        </Button>
                    </div>
                </Transition>
            </div>
        </div>

        <div v-if="element.type === 5">
            <Switch :id="`${element.id}-${element.uid}`" :checked="value.value === '1'" @update:checked="v => value.value = (v ? '1' : '0')" :disabled="props.readonly ? true : false" />
        </div>

        <div v-if="element.type === 6">
            <VoiceRecorder :readonly="props.readonly" @update:recording="v => value.file = v" />
        </div>

        <div v-if="element.type === 7">
            <CameraRecorder :readonly="props.readonly" @update:recording="v => value.file = v" />
        </div>

        <div v-if="element.type === 8">
            <ScreenRecorder :readonly="props.readonly" @update:recording="v => value.file = v" />
        </div>

        <Number v-else-if="element.type === 9" :title="element.name" :modelValue="parseFloat(value.value)" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

        <Dropdown v-else-if="element.type === 10" :items="store.lookups.options[store.ei('ElementType', 10)].map(x => ({ label: x, value: x }))" :title="t('common.select')" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Dropdown v-else-if="element.type === 11" :items="store.lookups.options[store.ei('ElementType', 11)].map(x => ({ label: x, value: x }))" :title="t('common.select')" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Dropdown v-else-if="element.type === 12" :items="store.lookups.options[store.ei('ElementType', 12)].map(x => ({ label: x, value: x }))" :title="t('common.select')" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Dropdown v-else-if="element.type === 14" :items="store.lookups.options[store.ei('ElementType', 14)].map(x => ({ label: x, value: x }))" :title="t('common.select')" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

        <Number v-else-if="element.type === 13" :title="element.name" :modelValue="parseFloat(value.value)" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

    </div>
</template>


<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>