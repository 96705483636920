<script setup>

import { cn } from '@/lib/utils'
import { useRoute } from 'vue-router';
import { useGlobalEventBus } from '@/stores/GlobalEventBus';

const route = useRoute();
const bus = useGlobalEventBus();

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
    nav: {
        type: Array,
        required: true,
        default: () => [],
    },
})

const isCurrent = (href) => {
    let url = route.path;
    if (!url.startsWith('#')) {
        url = '#' + url;
    }

    return url === href;
};

const linkClick = () => {
    bus.addEvent({
        type: bus.objTypes.navClick,
        value: route,
    });
};

</script>

<template>
    <Layout>
        <div class="space-y-6 pb-16 md:block">
            <div class="flex flex-row items-center justify-between w-full">
                <div class="space-y-0.5">
                    <h2 class="text-2xl font-bold tracking-tight">
                        {{ props.title }}
                    </h2>
                    <p class="text-muted-foreground">
                        {{ props.description }}
                    </p>
                </div>
                <Card class="flex flex-col p-1 gap-1">
                    <Button v-for="item in props.nav" :key="item.title" as="a" :href="item.href" @click="linkClick" variant="ghost" :class="cn('w-full flex flex-row justify-between gap-4',
                        isCurrent(item.href) && 'bg-muted hover:bg-muted',
                    )">
                        <span>
                            {{ item.title }}
                        </span>
                        <Icon :icon="item.icon" class="shrink-0 h-5 w-5" />
                    </Button>
                </Card>
            </div>
            <Separator class="my-6" />
            <div class="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
                <div class="flex-1">
                    <div class="space-y-6">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>