<script setup>

import { setLocale } from '@/localization/locale';
import { useI18n } from 'vue-i18n';
import { icons } from '@/utils/icons';

const { t } = useI18n();

const changeLocale = (event) => {
    setLocale(event.target.value);
}
</script>

<template>
    <div class="locale-changer text-xs flex flex-row items-center">
        <Icon :icon="icons.languages" class="shrink-0" />
        <select @change="changeLocale" v-model="$i18n.locale" class="select select-bordered w-full max-w-xs">
            <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
                {{ t(`languages.${locale}`) }}
            </option>
        </select>
    </div>
</template>