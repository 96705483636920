<script setup>

import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';

import { useForm } from 'vee-validate';
import { vAutoAnimate } from '@formkit/auto-animate/vue';
import { toTypedSchema } from '@vee-validate/zod';
import { useGlobalEventBus } from '@/stores/GlobalEventBus';
import * as z from 'zod';
import { toast } from 'vue-sonner';

import { form } from '@/utils/http';
import { icons } from '@/utils/icons';
import { useGlobalState } from '@/stores/GlobalState';
import VendorForm from './VendorForm.vue';

const { t } = useI18n();
const store = useGlobalState();
const bus = useGlobalEventBus();

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
});

const emits = defineEmits(['update:modelValue', 'close']);

const isPosting = ref(false);
const hasScanned = ref(false);
const isScanning = ref(false);

const formSchema = toTypedSchema(z.object({
    id: z.number().optional().nullable().default(null),
    vendorId: z.number({ message: t('validation.vendorIsRequired') }).default(null),
    number: z.string({ message: t('validation.poNumberIsRequired') }).min(1, { message: t('validation.poNumberIsRequired') }).default(null),
    issueDate: z.string({ message: t('validation.issueDateIsRequired') }).min(1, { message: t('validation.issueDateIsRequired') }).default(null),
    dueDate: z.string({ message: t('validation.dueDateIsRequired') }).min(1, { message: t('validation.dueDateIsRequired') }).default(null),
    notes: z.string().optional().nullable().default(null),
    terms: z.string().optional().nullable().default(null),
    subTotal: z.number().optional().nullable().default(null),
    tax: z.number().optional().nullable().default(null),
    shipping: z.number().optional().nullable().default(null),
    total: z.number().optional().nullable().default(null),
    file: z.any().nullable().optional().default(null),
}))

const { handleSubmit, errors, setValues, resetForm, meta, values } = useForm({
    validationSchema: formSchema,
})

const errorMessages = computed(() => Object.keys(errors.value).map(key => errors.value[key]));

const onSubmit = handleSubmit(async (values, { resetForm }) => {
    try {
        if (!meta.value.valid) {
            return;
        }

        if (isPosting.value) {
            return;
        }

        isPosting.value = true;

        const { data } = await form(`procurement/pos`, values);

        store.refershLookups();

        isPosting.value = false;
        emits('update:modelValue', {
            old: props.item,
            new: data
        });

        setValues({});
        resetForm();

        bus.addEvent({
            type: bus.objTypes.poCreate,
            value: data,
        });
    } catch (e) {
        isPosting.value = false;

        toast(t('common.failedToSave'), {
            description: '',
            action: {
                label: t('common.close'),
            },
        })
    }
})

watch(() => props.item, (value) => {
    if (!value) {
        setValues({});
        resetForm();
        return;
    }

    setValues({
        ...value,
    });
}, { immediate: true });

onMounted(() => {
    document.addEventListener('keydown', close);
    document.addEventListener('keydown', save);
});

onUnmounted(() => {
    setValues({});
    resetForm();

    document.removeEventListener('keydown', close);
    document.removeEventListener('keydown', save);
});

const close = (e) => {
    if (isPosting.value) {
        return;
    }

    if (e.key === 'Escape') {
        emits('close');
    }
}

const save = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
        onSubmit();
    }
}

const scan = async () => {
    if (!values.file) {
        return;
    }

    if (isScanning.value) {
        return;
    }

    isScanning.value = true;

    const { data } = await form(`/global/agents/lumi/po`, {
        payload: {
            id: uuidv4(),
            files: [values.file],
            links: [],
            instructions: null,
            references: [],
        },
    });

    setValues({
        ...data,
    });

    isScanning.value = false;
    hasScanned.value = true;
}

const isVendorModalOpen = ref(false);
const vendorItem = ref(null);

const createVendor = () => {
    vendorItem.value = {};
    isVendorModalOpen.value = true;
}

const closeVendorModal = () => {
    isVendorModalOpen.value = false;
    vendorItem.value = {};
}
const addNewVendor = (v) => {
    if (!store.lookups.vendors.find(c => c.id === v.id)) {
        store.lookups.vendors.push(v);
    }

    closeVendorModal();
    addVendor(v.id);
}

const addVendor = (id) => {
    const isFound = values.vendorId === id;
    if (isFound) {
        return;
    }

    setValues({ vendorId: id });
}


</script>

<template>

    <div class="flex flex-col justify-center sm:items-center">
        <Card class="w-full p-4">

            <CardHeader>
                <CardTitle>
                    <div class="flex flex-row items-center justify-between">
                        <span class="flex flex-row items-center gap-2">
                            <Icon :icon="icons.purchaseOrder" class="shrink-0" />
                            <div class="font-medium">{{ props.item?.id ? t('common.edit') : t('common.create') }}</div>
                        </span>
                        <ConfirmClose :isDisabled="isPosting" @close="emits('close')" />
                    </div>
                </CardTitle>
            </CardHeader>

            <CardContent class="flex flex-col w-full">

                <div class="flex flex-row items-center gap-4 w-full">
                    <div>
                        <Transition mode="out-in">
                            <Dropdown v-if="store.types.vendors.length > 0" :items="store.types.vendors" :title="`${t('common.select')} ${t('common.vendor')}`" :icon="icons.vendors" :modelValue="values.vendorId" @update:modelValue="v => addVendor(v)" :forget="true">
                                <template #individual>
                                    <div class="flex flex-col items-center mb-1">
                                        <Button @click="createVendor" class="w-full" size="sm" variant="secondary">
                                            {{ t('common.create') }} {{ t('common.new') }}
                                            <Icon :icon="icons.plus" class="ml-2" />
                                        </Button>
                                    </div>
                                </template>
                            </Dropdown>
                            <Button v-else class="border-dashed" @click="createVendor">
                                {{ t('common.create') }} {{ t('common.vendor') }}
                            </Button>
                        </Transition>
                    </div>
                    <Input type="file" :placeholder="t('common.document')" @change="e => setValues({ file: e?.target.files[0] })" @blur="e => setValues({ file: e?.target.files[0] })" @reset="e => setValues({ file: e?.target.files[0] })" />

                    <ProcessingButton :disabled="!values.file || isScanning" @click="scan" :processing="isScanning" variant="secondary" :text="t('common.scan')" />
                </div>

                <Transition mode="out-in">
                    <div v-if="hasScanned" class="flex flex-col w-full mt-4">
                        <div class="flex flex-row items-center justify-between gap-4">

                            <div class="w-1/3 ">
                                <FormField v-slot="{ componentField }" name="number">
                                    <FormItem v-auto-animate>
                                        <FormLabel>{{ t('common.number') }}</FormLabel>
                                        <FormControl>
                                            <Input type="text" :placeholder="t('common.number')" v-bind="componentField" />
                                        </FormControl>
                                        <FormDescription>
                                        </FormDescription>
                                    </FormItem>
                                </FormField>
                            </div>


                            <div class="w-1/3 mb-2">
                                <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">{{ t('common.issueDate') }}</label>
                                <DatePicker :modelValue="values.issueDate" :title="t('common.issueDate')" @update:modelValue="v => setValues({ issueDate: v })" class="w-full" />
                            </div>


                            <div class="w-1/3 mb-2">
                                <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">{{ t('common.dueDate') }}</label>
                                <DatePicker :modelValue="values.dueDate" :title="t('common.dueDate')" @update:modelValue="v => setValues({ dueDate: v })" class="w-full" />
                            </div>

                        </div>

                        <div class="flex flex-row items-center justify-between gap-4">

                            <div class="w-1/4">
                                <FormField v-slot="{ componentField }" name="subTotal">
                                    <FormItem v-auto-animate>
                                        <FormLabel>{{ t('common.subTotal') }}</FormLabel>
                                        <FormControl>
                                            <Input type="number" :placeholder="t('common.subTotal')" v-bind="componentField" />
                                        </FormControl>
                                        <FormDescription>
                                        </FormDescription>
                                    </FormItem>
                                </FormField>
                            </div>


                            <div class="w-1/4">
                                <FormField v-slot="{ componentField }" name="tax">
                                    <FormItem v-auto-animate>
                                        <FormLabel>{{ t('common.tax') }}</FormLabel>
                                        <FormControl>
                                            <Input type="number" :placeholder="t('common.tax')" v-bind="componentField" />
                                        </FormControl>
                                        <FormDescription>
                                        </FormDescription>
                                    </FormItem>
                                </FormField>
                            </div>


                            <div class="w-1/4">
                                <FormField v-slot="{ componentField }" name="shipping">
                                    <FormItem v-auto-animate>
                                        <FormLabel>{{ t('common.shipping') }}</FormLabel>
                                        <FormControl>
                                            <Input type="number" :placeholder="t('common.shipping')" v-bind="componentField" />
                                        </FormControl>
                                        <FormDescription>
                                        </FormDescription>
                                    </FormItem>
                                </FormField>
                            </div>

                            <div class="w-1/4">
                                <FormField v-slot="{ componentField }" name="total">
                                    <FormItem v-auto-animate>
                                        <FormLabel>{{ t('common.total') }}</FormLabel>
                                        <FormControl>
                                            <Input type="number" :placeholder="t('common.total')" v-bind="componentField" />
                                        </FormControl>
                                        <FormDescription>
                                        </FormDescription>
                                    </FormItem>
                                </FormField>
                            </div>

                        </div>

                        <div>
                            <FormField v-slot="{ componentField }" name="terms">
                                <FormItem v-auto-animate>
                                    <FormLabel>{{ t('common.terms') }}</FormLabel>
                                    <FormControl>
                                        <Textarea :placeholder="t('common.terms')" v-bind="componentField"></Textarea>
                                    </FormControl>
                                    <FormDescription>
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            </FormField>
                        </div>

                        <div>
                            <FormField v-slot="{ componentField }" name="notes">
                                <FormItem v-auto-animate>
                                    <FormLabel>{{ t('common.notes') }}</FormLabel>
                                    <FormControl>
                                        <Textarea :placeholder="t('common.notes')" v-bind="componentField"></Textarea>
                                    </FormControl>
                                    <FormDescription>
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            </FormField>
                        </div>

                    </div>

                </Transition>
            </CardContent>

            <Transition mode="out-in">
                <CardFooter v-if="hasScanned" class="flexflex-row px-6 pb-6 gap-2 justify-between">
                    <Transition mode="out-in">
                        <AnimatedList v-if="errorMessages.length > 0" :items="errorMessages" :hasRemove="false">
                            <template #item="{ item, index }">
                                <Label class="font-semibold grow" style="color:#DB4325">{{ item }}</Label>
                            </template>
                        </AnimatedList>
                        <span v-else></span>
                    </Transition>
                    <ProcessingButton :disabled="isPosting || !meta.valid || isScanning" @click="onSubmit" :processing="isPosting" variant="default" :text="props.item?.id ? t('common.edit') : t('common.send')" />
                </CardFooter>
            </Transition>
        </Card>
    </div>

    <Modal :open="isVendorModalOpen" @update:open="v => isVendorModalOpen = v">

        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <div class="flex flex-col grow">
                    <VendorForm :item="vendorItem" @close="closeVendorModal" @update:modelValue="v => addNewVendor(v.new)" />
                </div>
            </div>
        </template>

    </Modal>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>