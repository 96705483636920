<script setup>

import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGlobalState } from '@/stores/GlobalState';
import { PanelLeft } from 'lucide-vue-next'
import { isCurrent, logout } from '@/utils/nav';
import { useI18n } from 'vue-i18n';
import { icons } from '@/utils/icons';
import { getDecodedToken } from '@/utils/auth';
import { initials } from '@/lib/utils';
import { createReusableTemplate } from '@vueuse/core'
import { useGlobalEventBus } from '@/stores/GlobalEventBus';

import { buildNavData } from "@/utils/nav";
import Creator from '@/views/buyers/dashboards/packs/components/Buttons/Creator.vue';

const [BuyerNavTemplate, BuyerNav] = createReusableTemplate();
const [VendorNavTemplate, VendorNav] = createReusableTemplate();


import { getCurrentSubscription } from '@/utils/auth';
import LangSwitcher from '../Components/LangSwitcher.vue';

const { t } = useI18n();
const bus = useGlobalEventBus();
const route = useRoute();
const router = useRouter();
const store = useGlobalState();
const currentUser = computed(() => getDecodedToken());

const buyerLinks = computed(() => router.getRoutes().filter(x => x.meta?.nav === true && x.meta.role.includes('Buyer')).map((child) => ({
  title: t(child.meta.title),
  icon: child.meta.icon,
  ...buildNavData(child, route),
})));

const vendorLinks = computed(() => router.getRoutes().filter(x => x.meta?.nav === true && x.meta.role.includes('Vendor')).map((child) => ({
  title: t(child.meta.title),
  icon: child.meta.icon,
  ...buildNavData(child, route),
})));

const currentSub = computed(() => getCurrentSubscription());

const linkClick = (link) => {
  bus.addEvent({
    type: bus.objTypes.navClick,
    value: link,
  });
};

</script>

<template>
  <BuyerNavTemplate v-slot="{ minimized }">

    <a v-if="!minimized" href="#" class="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground" :class="route.name === 'buyer-home' ? 'bg-slate-200' : ''">
      <AlinorLogo :titleSettings="{ show: false }" class="h-6 w-6 transition-all hover:scale-110" />
      Alinor
    </a>

    <a v-if="!minimized" v-for="(link, index) in buyerLinks.filter((x) => x.hasAccess)" :key="index" :href="link.url" @click="linkClick(link)" class="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground">
      <Icon :icon="link.icon" class="h-5 w-5 transition-all hover:scale-110 shrink-0" :class="isCurrent(route, link) ? 'bg-slate-200' : ''" />
      {{ link.title }}
    </a>

    <Tooltip v-if="minimized">
      <TooltipTrigger>
        <a href="#" class="h-9 w-9 flex items-center justify-center rounded-md md:h-8 md:w-8 shrink-0" :class="route.name === 'buyer-home' ? 'bg-slate-200' : ''">
          <AlinorLogo :titleSettings="{ show: false }" class="h-6 w-6 transition-all hover:scale-110" />
          <span class="sr-only">Alinor</span>
        </a>
      </TooltipTrigger>
      <TooltipContent side="right">
        {{ t('common.dashboard') }}
      </TooltipContent>
    </Tooltip>

    <Tooltip v-if="minimized" v-for="(link, index) in buyerLinks.filter((x) => x.hasAccess)" :key="index">
      <TooltipTrigger>
        <a :href="link.url" @click="linkClick(link)" class="h-9 w-9 flex items-center justify-center rounded-md md:h-8 md:w-8 shrink-0" :class="isCurrent(route, link) ? 'bg-slate-200' : ''">
          <Icon :icon="link.icon" class="h-5 w-5 transition-all hover:scale-110 shrink-0" />
          <span class="sr-only">{{ link.title }}</span>
        </a>
      </TooltipTrigger>
      <TooltipContent side="right">
        {{ link.title }}
      </TooltipContent>
    </Tooltip>

    <Separator />

    <Creator />

  </BuyerNavTemplate>

  <VendorNavTemplate v-slot="{ minimized }">

    <a v-if="!minimized" href="#/hi" class="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground" :class="route.name === 'vendor-home' ? 'bg-slate-200' : ''">
      <AlinorLogo :titleSettings="{ show: false }" class="h-6 w-6 transition-all hover:scale-110" />
      Alinor
    </a>
    <a v-if="!minimized" v-for="(link, index) in vendorLinks.filter((x) => x.hasAccess)" :key="index" :href="link.url" @click="linkClick(link)" class="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground">
      <Icon :icon="link.icon" class="h-5 w-5 transition-all hover:scale-110 shrink-0" :class="isCurrent(route, link) ? 'bg-slate-200' : ''" />
      {{ link.title }}
    </a>

    <Tooltip v-if="minimized">
      <TooltipTrigger>
        <a href="#/hi" class="h-9 w-9 flex items-center justify-center rounded-md md:h-8 md:w-8 shrink-0" :class="route.name === 'vendor-home' ? 'bg-slate-200' : ''">
          <AlinorLogo :titleSettings="{ show: false }" class="h-6 w-6 transition-all hover:scale-110" />
          <span class="sr-only">Alinor</span>
        </a>
      </TooltipTrigger>
      <TooltipContent side="right">
        {{ t('common.dashboard') }}
      </TooltipContent>
    </Tooltip>
    <Tooltip v-if="minimized" v-for="(link, index) in vendorLinks.filter((x) => x.hasAccess)" :key="index">
      <TooltipTrigger>
        <a :href="link.url" @click="linkClick(link)" class="h-9 w-9 flex items-center justify-center rounded-md md:h-8 md:w-8 shrink-0" :class="isCurrent(route, link) ? 'bg-slate-200' : ''">
          <Icon :icon="link.icon" class="h-5 w-5 transition-all hover:scale-110 shrink-0" />
          <span class="sr-only">{{ link.title }}</span>
        </a>
      </TooltipTrigger>
      <TooltipContent side="right">
        {{ link.title }}
      </TooltipContent>
    </Tooltip>

  </VendorNavTemplate>

  <TooltipProvider :delay-duration="0">
    <div class="flex min-h-screen w-full flex-col bg-muted/40">
      <aside class="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
        <nav class="flex flex-col items-center gap-4 px-2 py-4">
          <BuyerNav v-if="currentSub.isBuyer" :minimized="true" />
          <VendorNav v-else :minimized="true" />
        </nav>
        <nav class="mt-auto flex flex-col items-center gap-4 px-2 py-4">
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <Button variant="secondary" size="icon" class="rounded-full">
                <Avatar>
                  <AvatarFallback>{{ initials(`${currentUser.firstName} ${currentUser.lastName}`) }}</AvatarFallback>
                </Avatar>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <SubscriptionSwitcher />
              <DropdownMenuSeparator />
              <LangSwitcher />
              <DropdownMenuSeparator />
              <DropdownMenuItem @click="logout(router)" class=" hover:cursor-pointer flex flex-row items-center justify-between w-full">
                <span>
                  {{ t('nav.logout') }}
                </span>
                <Icon :icon="icons.logout" class="shrink-0" />
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </nav>
      </aside>
      <div class="flex flex-col sm:gap-4 sm:pl-14">
        <header class="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
          <Sheet>
            <SheetTrigger as-child>
              <Button size="sm" variant="outline" class="sm:hidden">
                <PanelLeft class="h-5 w-5" />
                <span class="sr-only">Toggle Menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" class="sm:max-w-xs">
              <nav class="grid gap-6 text-lg font-medium">
                <BuyerNav v-if="currentSub.isBuyer" :minimized="false" />
                <VendorNav v-else :minimized="false" />
              </nav>
            </SheetContent>
          </Sheet>
        </header>
        <ScrollArea class="h-[92dvh] w-full">
          <main class="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <div v-if="store.isLoading" class="h-96 w-full rounded-xl p-2">
              <Skeleton class="h-full rounded-xl" />
            </div>
            <div v-show="!store.isLoading">
              <slot></slot>
            </div>
          </main>
        </ScrollArea>
      </div>
    </div>

  </TooltipProvider>
</template>




<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.10s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>