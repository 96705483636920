<script setup>

import { ref, watch } from 'vue';

import AutomationForm from '@/views/_shared/Components/Forms/AutomationForm.vue';
import ComponentForm from '@/views/_shared/Components/Forms/ComponentForm.vue';
import POForm from '@/views/_shared/Components/Forms/POForm.vue';
import ProjectForm from '@/views/_shared/Components/Forms/ProjectForm.vue';
import SourceForm from '@/views/_shared/Components/Forms/SourceForm.vue';
import UserForm from '@/views/_shared/Components/Forms/UserForm.vue';
import VendorForm from '@/views/_shared/Components/Forms/VendorForm.vue';

const props = defineProps({
    item: Object,
    component: String,
    isOpen: Boolean,
});

const isModalOpen = ref(props.isOpen);

const emits = defineEmits('update:modelValue', 'close', 'update:open');

watch(() => props.isOpen, (v) => {
    isModalOpen.value = v;
    emits('update:open', v);
});

</script>

<template>
    <Modal :open="isModalOpen" @update:open="v => isModalOpen = v">

        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <div v-if="props.item" class="flex flex-col grow">
                    <component :is="props.component" :item="props.item" @close="emits('close')" @update:modelValue="v => emits('update:modelValue', v)" />
                </div>
            </div>
        </template>

    </Modal>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>