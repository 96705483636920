import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useAgentState = defineStore('stores.agentState', () => {
    const agents = ref({
        lumi: null,
        satu: null,
        kivi: null,
    });

    function setLumi(agent) {
        agents.value.lumi = agent;
    }

    function setSatu(agent) {
        agents.value.satu = agent;
    }

    function setKivi(agent) {
        agents.value.kivi = agent;
    }

    return {
        agents,
        setLumi,
        setSatu,
        setKivi,
    };
});