import { defineStore } from 'pinia'
import { ref, computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';

export const useGlobalEventBus = defineStore('stores.globalBus', () => {

    const objStore = ref({
        packageCreate: 'package-create',
        projectCreate: 'project-create',
        poCreate: 'po-create',
        packageCopy: 'package-copy',
        navClick: 'nav-click',
    });
    const objTypes = computed(() => objStore.value);

    const events = ref([]);

    function addEvent(event) {
        events.value.push({
            id: uuidv4(),
            event,
        });
    }

    function removeEvent(id) {
        const index = events.value.findIndex(e => e.id === id);
        if (index > -1) {
            events.value.splice(index, 1);
        }
    }

    function lastEvent() {
        return events.value[events.value.length - 1];
    }

    function handleEvent(desiredType, callbackOrPromise) {
        const e = lastEvent();
        if (!e) {
            return;
        }

        const { value, type } = e.event;
        if (type !== desiredType) {
            return;
        }

        try {
            if (typeof callbackOrPromise === 'function') {
                callbackOrPromise(value);
                removeEvent(e.id);
            } else if (callbackOrPromise instanceof Promise) {
                callbackOrPromise.finally(_ => {
                    removeEvent(e.id);
                });
            }
        } finally {
            removeEvent(e.id);
        }
    }

    return {
        events,
        objTypes,
        addEvent,
        handleEvent,
    }
});