<script setup>

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { setToken, getAllSubscriptions, getCurrentSubscription, isUserAuthenticated } from '@/utils/auth';
import { useGlobalState } from '@/stores/GlobalState';

import { post } from '@/utils/http';

import { icons } from '@/utils/icons';

import { navigateHome } from "@/utils/nav";

const { t } = useI18n();
const router = useRouter();
const store = useGlobalState();

const subs = computed(() => isUserAuthenticated() ? getAllSubscriptions() : []);
const current = computed(() => isUserAuthenticated() ? getCurrentSubscription() : {});

const swt = (tenantId) => {
    if (tenantId === current.value.TenantId) {
        return;
    }

    post('auth/token/switch', {
        current: current.value.TenantId,
        other: tenantId
    }).then((response) => {
        if (response.status !== 200) {
            //TODO(T): MESSAGE THE USER
            return;
        }

        setToken(response.data.jwt);
        navigateHome(router);

        store.fetchLookups(true);
    }).catch((error) => {
        console.error(error);
    })
}

</script>

<template>
    <Dropdown v-if="subs.length > 1" :modelValue="current.TenantId" :items="subs.map(x => ({ label: `${x.isBuyer ? t('common.buyer') : t('common.vendor')}: ${x.Name}`, value: x.TenantId }))" :title="t('common.switch')" :icon="icons.vendors" @update:modelValue="v => swt(v)" />
</template>
