<script setup>

const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    pageInfo: {
        type: Object,
        required: true
    }
});

const emits = defineEmits(['update:page']);

const updatePage = (page) => {
    if (!props.pageInfo.currentPage) {
        return;
    }

    if (props.pageInfo.currentPage === page) {
        return;
    }

    emits('update:page', page);
}

</script>

<template>
    <Transition mode="out-in">
        <div v-if="items && pageInfo && items.length !== 0 && pageInfo.pageCount > 1" class="flex justify-between py-3">
            <Pagination v-slot="{ page }" :page="pageInfo.currentPage" :default-page="1" :itemsPerPage="pageInfo.pageSize" :total="pageInfo.rowCount" :sibling-count="1" show-edges @update:page="updatePage">
                <PaginationList v-slot="{ items }" class="flex items-center gap-1">
                    <PaginationFirst />
                    <PaginationPrev />

                    <template v-for="(item, index) in items">
                        <PaginationListItem v-if="item.type === 'page'" :key="index" :value="item.value" as-child>
                            <Button class="w-10 h-10 p-0" :variant="item.value === page ? 'default' : 'outline'">
                                {{ item.value }}
                            </Button>
                        </PaginationListItem>
                        <PaginationEllipsis v-else :key="item.type" :index="index" />
                    </template>

                    <PaginationNext />
                    <PaginationLast />
                </PaginationList>
            </Pagination>
        </div>
    </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
