<script setup>

import { computed, ref } from 'vue'
import { CalendarDate, DateFormatter, parseDate } from '@internationalized/date'
import { toDate } from 'radix-vue/date'
import { CalendarIcon } from '@radix-icons/vue'

import { cn } from '@/lib/utils'

const props = defineProps({
    title: {
        type: String,
        required: true,
        default: 'Date'
    },
    modelValue: {
        type: String,
        default: undefined
    },
    open: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['update:modelValue']);

const isOpen = ref(props.open);
const selectedDate = ref(undefined);

const value = computed({
    get: () => {
        try {
            if (props.modelValue) {
                return parseDate(props.modelValue);
            }

            if (selectedDate.value) {
                return parseDate(selectedDate.value);
            }
        } catch (e) {
            return undefined;
        }

        return undefined;
    },
    set: val => val,
});

const df = new DateFormatter('en-US', {
    dateStyle: 'long',
})

const updateValue = (value) => {
    if (value) {
        selectedDate.value = value.toString();
    } else {
        selectedDate.value = undefined;
    }

    isOpen.value = false;
    emits('update:modelValue', selectedDate.value);
}

</script>

<template>
    <div class="min-w-fit">
        <div class="flex flex-col">
            <Popover :open="isOpen" @update:open="o => isOpen = o">
                <PopoverTrigger as-child>
                    <Button :disabled="props.readonly ? true : false" variant="outline" :class="cn('ps-3 text-start font-normal flex flex-row justify-between', !value && 'text-muted-foreground')">
                        <Transition mode="out-in">
                            <span v-if="value">
                                {{ df.format(toDate(value)) }}
                            </span>
                            <span v-else>
                                {{ props.title }}
                            </span>
                        </Transition>
                        <CalendarIcon class="ms-2 h-4 w-4 opacity-50" />
                    </Button>
                    <input hidden>
                </PopoverTrigger>
                <PopoverContent class="w-auto p-0">
                    <Calendar :weekStartsOn="1" :pagedNavigation="true" :modelValue="value" :calendar-label="props.title" initial-focus :min-value="new CalendarDate((new Date()).getFullYear(), 1, 1)" @update:modelValue="(v) => updateValue(v)" :readonly="props.readonly" />
                </PopoverContent>
            </Popover>
        </div>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
