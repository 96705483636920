<script setup>

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { icons } from '@/utils/icons';
import { useGlobalState } from '@/stores/GlobalState';
import UserForm from '../../Forms/UserForm.vue';

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    componenet: {
        type: Object,
        required: false
    }
});

const isOpen = ref(false);

const { t } = useI18n();
const store = useGlobalState();

const emits = defineEmits(['update:duplicate', 'update:remove', 'update:value']);

const isUserModalOpen = ref(false);
const userItem = ref(null);

const createUser = (c, e) => {
    userItem.value = {
        e: e,
        c: c,
    };
    isUserModalOpen.value = true;
}

const closeUserModal = () => {
    isUserModalOpen.value = false;
    userItem.value = {};
}

const addNewUser = (v) => {
    if (!store.lookups.users.find(c => c.id === v.id)) {
        store.lookups.users.push(v);
    }

    if (userItem.value.c && userItem.value.e) {
        userItem.value.e.ownerId = v.id;
    } else {
        props.componenet.ownerId = v.id;
    }
    
    updateValue('ownerId', v.id);
    closeUserModal();
}

const updateValue = (key, v) => {
    props.item[key] = v;

    emits('update:value', props.item);
}

</script>

<template>

    <Tooltip>
        <TooltipTrigger>
            <Popover :open="isOpen" @update:open="o => isOpen = o">
                <PopoverTrigger as-child >
                    <Icon  :icon="icons.settings" class="shrink-0 text-muted-foreground text-xs" />
                </PopoverTrigger>
                <PopoverContent class="min-w-64 w-auto">
                    <div class="grid gap-4">
                        <div class="flex flex-row justify-between items-center">
                            <h4 class="font-medium leading-none">
                                {{ t('common.settings') }}
                            </h4>
                            <Button variant="outline" size="sm" @click="isOpen = !isOpen">
                                <Icon :icon="icons.check" class="shrink-0" />
                            </Button>
                        </div>
                        <div class="grid gap-2">
                            <div class="grid grid-cols-3 items-center gap-4">
                                <Label for="width">{{ t('common.name') }}</Label>
                                <Input :modelValue="props.item.name" @update:modelValue="v => updateValue('name', v)" class="col-span-2 h-8" />
                            </div>
                            <div class="grid grid-cols-3 items-center gap-4">
                                <Label :for="props.item.uid" class="hover:cursor-pointer">{{ t('common.required') }}</Label>
                                <Switch :id="props.item.uid" :checked="props.item.isRequired" @update:checked="() => updateValue('isRequired', !props.item.isRequired)" />
                            </div>
                            <Transition mode="out-in">
                                <div v-if="props.componenet" class="grid grid-cols-3 items-center gap-4">
                                    <Label for="width">{{ t('common.assignToUser') }}</Label>
                                    <div class="flex flex-row items-center gap-1">
                                        <Dropdown :items="store.types.users" :title="t('common.person')" :icon="icons.user" :modelValue="props.item.ownerId" @update:modelValue="v => updateValue('ownerId', v)">
                                            <template #individual>
                                                <div class="flex flex-col items-center mb-1">
                                                    <Button @click="createUser(props.componenet, props.item)" class="w-full" size="sm" variant="secondary">
                                                        {{ t('common.create') }} {{ t('common.new') }}
                                                        <Icon :icon="icons.plus" class="ml-2" />
                                                    </Button>
                                                </div>
                                            </template>
                                        </Dropdown>
                                        <Transition mode="out-in">
                                            <Button v-if="props.item.ownerId" @click="updateValue('ownerId', null)" variant="secondary" size="sm" class="shrink-0">
                                                <Icon :icon="icons.xxx" class="shrink-0" />
                                            </Button>
                                        </Transition>
                                    </div>
                                </div>
                            </Transition>
                            <div class="grid grid-cols-3 items-center gap-4">
                                <Label for="maxWidth">{{ t('common.howManyDoYouWant') }}</Label>
                                <Dropdown :items="store.enums.InputCount" :title="t('common.howManyDoYouWant')" :icon="icons.slidesH" :modelValue="props.item.inputCount" @update:modelValue="v => updateValue('inputCount', v)" />
                            </div>
                            <Transition mode="out-in">
                                <div v-if="props.item.inputCount === 1" class="grid grid-cols-3 items-center gap-4">
                                    <Label for="height">{{ t('common.limit') }}</Label>
                                    <Number :title="t('common.limit')" :modelValue="props.item.responseLimit" @update:modelValue="v => updateValue('responseLimit', v)" :min="1" :step="1" />
                                </div>
                            </Transition>
                            <div class="grid grid-cols-3 items-center gap-4">
                                <Label for="width">{{ t('common.actions') }}</Label>
                                <div class="flex flex-row items-center gap-1">
                                    <Transition mode="out-in">
                                        <div v-if="props.componenet">
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <Button size="sm" class="shrink-0" variant="outline" @click="emits('update:duplicate', props.item)">
                                                        <Icon :icon="icons.copy" class="shrink-0" />
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    {{ t('common.duplicate') }}
                                                </TooltipContent>
                                            </Tooltip>
                                        </div>
                                    </Transition>
                                    <Button @click="emits('update:remove', props.item)" class="text-destructive" size="sm" variant="outline">
                                        <Icon :icon="icons.trash" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        </TooltipTrigger>
        <TooltipContent>
            {{ t('common.settings') }}
        </TooltipContent>
    </Tooltip>

    <Modal :open="isUserModalOpen" @update:open="v => isUserModalOpen = v">

        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <div class="flex flex-col grow">
                    <UserForm :item="userItem" @close="closeUserModal" @update:modelValue="v => addNewUser(v.new)" />
                </div>
            </div>
        </template>

    </Modal>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>