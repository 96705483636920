<script setup>

import { ref, computed, watch } from 'vue';
import { useGlobalState } from '@/stores/GlobalState';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';

import { handleFilesChange, addRef } from '@/lib/utils';

import { icons } from '@/utils/icons';

const store = useGlobalState();
const { t } = useI18n();

const emits = defineEmits(['update:click']);

const props = defineProps({
    placeholder: {
        type: String,
        default: null,
    },
    buttonText: {
        type: String,
        default: null,
    },
    w: {
        type: String,
        default: 'sm:w-[850px]',
    },
    bg: {
        type: String,
        default: 'bg-white',
    },
    isPosting: {
        type: Boolean,
        default: false,
    },
});

const randomInputName = computed(()=> `ref-input-${uuidv4()}`);

const sources = ref({
    id: uuidv4(),
    files: [],
    links: [],
    references: [],
    referencesForm: [],
    linksForm: [],
    instructions: null,
    isLinksOpen: false,
    isSourcesOpen: false,
})

const addLinks = () => {
    sources.value.links = sources.value.links.concat(sources.value.linksForm.filter(x => x).map(x => x.trim()));
    sources.value.linksForm = [];
    sources.value.isLinksOpen = false;
}

const addSources = () => {
    const nonExisting = sources.value.referencesForm.filter(x => !sources.value.references.includes(x));
    sources.value.references = sources.value.references.concat(nonExisting);
    sources.value.referencesForm = [];
    sources.value.isSourcesOpen = false;
}

const clicked = () => {
    emits('update:click', {
        id: sources.value.id,
        files: sources.value.files.map(x => x.file),
        links: sources.value.links,
        references: sources.value.references,
        instructions: sources.value.instructions,
    });
}

watch(() => sources.value.files, (_) => {
    sources.value.id = uuidv4();
}, { deep: true });

watch(() => sources.value.links, (_) => {
    sources.value.id = uuidv4();
}, { deep: true });

watch(() => sources.value.references, (_) => {
    sources.value.id = uuidv4();
}, { deep: true });

watch(() => sources.value.instructions, (_) => {
    sources.value.id = uuidv4();
}, { deep: true });

</script>

<template>

    <Card class="flex flex-col justify-center" :class="[props.w, props.bg]">
        <div class="flex flex-row">
            <Textarea v-focus :modelValue="sources.instructions" @update:modelValue="v => sources.instructions = v" :placeholder="props.placeholder ?? t('common.whatRFxWouldYouLikeToCreate')" class="border-none shadow-none active:border-none focus:border-none focus-visible:ring-0 resize-none mt-2"></Textarea>
            <template v-if="$slots.upperC">
                <div class="pe-2 pt-2">
                    <slot name="upperC"></slot>
                </div>
            </template>
        </div>

        <div class="flex flex-row items-center justify-between p-2">
            <div class="flex flex-row items-center justify-between">
                <Transition mode="out-in">
                    <span v-if="store.types.references.length > 0">

                        <Tooltip>
                            <TooltipTrigger>
                                <Popover :open="sources.isSourcesOpen" @update:open="o => sources.isSourcesOpen = o">
                                    <PopoverTrigger as-child>
                                        <Button size="icon" variant="ghost" class="relative">
                                            <Icon :icon="icons.data" class="shrink-0" />
                                            <Indicator v-if="sources.references.length > 0" :text="sources.references.length.toString()" />
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent class="w-auto p-2">
                                        <div class="flex flex-row items-center justify-between gap-3">
                                            <h4 class="font-medium leading-none">
                                                <MultiDropdown :modelValue="sources.referencesForm" :items="store.types.references" :title="t('common.select')" :icon="icons.data" @update:modelValue="v => sources.referencesForm = v" />
                                            </h4>
                                            <Button size="icon" variant="outline" @click="addSources" :disabled="sources.referencesForm.length === 0">
                                                <Icon :icon="icons.check" class="shrink-0" />
                                            </Button>
                                        </div>
                                    </PopoverContent>
                                </Popover>

                            </TooltipTrigger>
                            <TooltipContent>
                                {{ t('common.addSourceReference') }}
                            </TooltipContent>
                        </Tooltip>

                    </span>
                </Transition>

                <Tooltip>
                    <TooltipTrigger>
                        <Button @click.stop="addRef(randomInputName)" size="icon" variant="ghost" class="relative">
                            <Icon :icon="icons.attachments" class="shrink-0" />
                            <Indicator v-if="sources.files.length > 0" :text="sources.files.length.toString()" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        {{ t('common.addFileReference') }}
                    </TooltipContent>
                </Tooltip>

                <Tooltip>
                    <TooltipTrigger>
                        <Popover :open="sources.isLinksOpen" @update:open="o => sources.isLinksOpen = o">
                            <PopoverTrigger as-child>
                                <Button size="icon" variant="ghost" class="relative">
                                    <Icon :icon="icons.internet" class="shrink-0" />
                                    <Indicator v-if="sources.links.length > 0" :text="sources.links.length.toString()" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent class="min-w-64 w-auto">
                                <div class="grid gap-4">
                                    <div class="space-y-2">
                                        <div class="flex flex-row items-center justify-between">
                                            <h4 class="font-medium leading-none">
                                                {{ t('common.links') }}
                                            </h4>
                                            <Button size="icon" variant="outline" @click="addLinks" :disabled="sources.linksForm.length === 0">
                                                <Icon :icon="icons.check" class="shrink-0" />
                                            </Button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col gap-2">

                                        <Transition mode="out-in">

                                            <AnimatedList v-if="sources.linksForm.length > 0" :items="sources.linksForm" :hasRemove="false">
                                                <template #item="{ item, index }">
                                                    <div class="flex flex-row items-center justify-between w-full gap-2">
                                                        <Input :modelValue="sources.linksForm[index]" @update:modelValue="v => sources.linksForm[index] = v" class="w-full" :placeholder="t('common.link')" />
                                                        <ConfirmClose @close="sources.linksForm.splice(index, 1)" />
                                                    </div>
                                                </template>
                                            </AnimatedList>

                                        </Transition>

                                        <Button size="icon" class="w-full border-dashed" variant="outline" @click="sources.linksForm.push('')">
                                            <Icon :icon="icons.plus" class="shrink-0" />
                                        </Button>
                                    </div>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </TooltipTrigger>
                    <TooltipContent>
                        {{ t('common.addWebsiteReference') }}
                    </TooltipContent>
                </Tooltip>

            </div>
            <ProcessingButton @click="clicked" :disabled="props.isPosting || !sources.instructions" :processing="props.isPosting" variant="secondary" :text="props.buttonText ?? t('common.create')" />
        </div>
        <input :id="randomInputName" type="file" multiple class="hidden" @change="e => handleFilesChange(e, sources)" @blur="e => handleFilesChange(e, sources)" @reset="e => handleFilesChange(e, sources)" />

        <Transition mode="out-in">

            <div v-if="sources.references.length > 0 || sources.files.length > 0 || sources.links.length > 0" class="flex flex-col gap-2 p-2">
                <Transition mode="out-in">

                    <Card v-if="sources.references.length > 0" class="flex flex-col justify-center bg-sky-50 p-1 ps-4">
                        <h1 class="text-sm font-semibold">
                            {{ t('common.sources') }}
                        </h1>
                        <AnimatedList :items="sources.references" :hasRemove="false">
                            <template #item="{ item, index }">
                                <div class="flex flex-row items-center w-full">
                                    <Button size="sm" variant="ghost" class="text-destructive" @click="sources.references.splice(index, 1)">
                                        <Icon :icon="icons.xxx" />
                                    </Button>
                                    <span class="text-xs">{{ store.types.references.find(x => x.value === item).label }}</span>
                                </div>
                            </template>
                        </AnimatedList>
                    </Card>

                </Transition>

                <Transition mode="out-in">

                    <Card v-if="sources.files.length > 0" class="flex flex-col justify-center bg-sky-50 p-1 ps-4">
                        <h1 class="text-sm font-semibold">
                            {{ t('common.files') }}
                        </h1>
                        <AnimatedList :items="sources.files" :hasRemove="false">
                            <template #item="{ item, index }">
                                <div class="flex flex-row items-center w-full">
                                    <Button size="sm" variant="ghost" class="text-destructive" @click="sources.files.splice(index, 1)">
                                        <Icon :icon="icons.xxx" />
                                    </Button>
                                    <span class="text-xs">{{ item.name }}</span>
                                </div>
                            </template>
                        </AnimatedList>
                    </Card>

                </Transition>

                <Transition mode="out-in">

                    <Card v-if="sources.links.length > 0" class="flex flex-col justify-center bg-sky-50 p-1 ps-4">
                        <h1 class="text-sm font-semibold">
                            {{ t('common.links') }}
                        </h1>
                        <AnimatedList :items="sources.links" :hasRemove="false">
                            <template #item="{ item, index }">
                                <div class="flex flex-row items-center w-full">
                                    <Button size="sm" variant="ghost" class="text-destructive" @click="sources.links.splice(index, 1)">
                                        <Icon :icon="icons.xxx" />
                                    </Button>
                                    <a class="text-xs" :href="item" target="_blank">{{ item }}</a>
                                </div>
                            </template>
                        </AnimatedList>
                    </Card>

                </Transition>
            </div>

        </Transition>
    </Card>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.10s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>