<script setup>

import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useGlobalState } from '@/stores/GlobalState';
import { useVersionStore } from '@/stores/Version';
import { isUserAuthenticated } from '@/utils/auth';
import { useI18n } from 'vue-i18n';

import { icons } from '@/utils/icons';

import { removeToken } from '@/utils/auth';
import { getLocale } from '@/localization/locale';
import { post } from '@/utils/http';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useGlobalState();
const versionManager = useVersionStore();
const hasNewVersion = ref(false);
const { locale } = useI18n();

onMounted(() => {
  const storedLocale = getLocale();
  if (storedLocale) {
    locale.value = storedLocale
  };

  if (!isUserAuthenticated()) {
    return;
  } else {
    store.fetchLookups();
  }

  post('auth/token/validate')
    .then(({ data }) => {
      if (!data.isValid) {
        removeToken();
        router.push({ name: 'login' });
        return;
      }
    })

});

versionManager.$subscribe((_, __) => {
  hasNewVersion.value = true;
});

const reload = () => {
  window.location.reload();
};

</script>

<template>
  <main>
    <router-view v-slot="{ Component }">
      <div :key="route.name">
        <Transition mode="out-in">
          <div v-if="hasNewVersion" class="absolute w-full flex items-center justify-center mt-0.5">
            <Alert class="w-6/12 z-50 border-yellow-300">

              <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row gap-2">
                  <Icon :icon="icons.info" class="h-4 w-4 shrink-0 text-yellow-300" />
                  <div class="flex flex-col">
                    <AlertTitle>{{ t('common.headesUp') }}</AlertTitle>
                    <AlertDescription>
                      {{ t('common.newVersionAvailable') }}
                    </AlertDescription>
                  </div>
                </div>
                <Button variant="default" @click="reload">
                  <Pulse class="mr-2" strongColor="bg-white" lightColor="bg-white" />
                  {{ t('common.update') }}
                </Button>
              </div>

            </Alert>
          </div>

        </Transition>
        <component :is="Component" />
      </div>
    </router-view>
  </main>
  <Toaster />
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
