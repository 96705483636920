import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs) => {
    return twMerge(clsx(inputs));
}

export function valueUpdater(updaterOrValue, ref) {
    ref.value = typeof updaterOrValue === 'function'
      ? updaterOrValue(ref.value)
      : updaterOrValue
  }

export const canPrev = (index) => {
    return index > 0;
}

export const canNext = (index, array) => {
    if (!array) {
        return false;
    }

    return index < array.length - 1;
}

export const moveBack = (index, array) => {
    if (!array) {
        return index;
    }

    if (canPrev(index, array)) {
        index = index - 1;
    }

    return index;
}

export const moveNext = (index, array) => {
    if (!array) {
        return index;
    }

    if (canNext(index, array)) {
        index = index + 1;
    }

    return index;
}

export const getVendorPackageStatusColor = (status) => {
    switch (status) {
        case 0:
            return 'bg-yellow-100 text-yellow-900'; // Yellow
        case 1:
            return 'bg-blue-100 text-blue-900'; // Blue
        case 2:
            return 'bg-teal-100 text-teal-900'; // Teal
        case 3:
            return 'bg-purple-100 text-purple-900'; // Purple
        case 4:
            return 'bg-teal-100 text-teal-900'; // Teal
        case 5:
            return 'bg-red-100 text-red-900'; // Red
        default:
            return 'bg-gray-100 text-gray-900'; // Gray
    }
};

export const getVendorComponentStatusColor = (status) => {
    switch (status) {
        case 0:
            return 'bg-yellow-100 text-yellow-900'; // Yellow
        case 1:
            return 'bg-teal-100 text-teal-900'; // Teal
        case 2:
            return 'bg-red-100 text-red-900'; // Red
        default:
            return 'bg-gray-100 text-gray-900'; // Gray
    }
};

export const getCommentStatusColor = (status) => {
    switch (status) {
        case 0:
            return 'bg-yellow-100 text-yellow-900'; // Yellow
        case 1:
            return 'bg-blue-100 text-blue-900'; // Blue
        case 3:
            return 'bg-teal-100 text-teal-900'; // Teal
        default:
            return '';
    }
}

export const getPOStatusColor = (status) => {
    switch (status) {
        case 0:
            return 'bg-gray-100 text-gray-900'; // Yellow
        case 1:
            return 'bg-blue-100 text-blue-900'; // Blue
        case 2:
            return 'bg-teal-100 text-teal-900'; // Teal
        case 3:
            return 'bg-red-100 text-red-900'; // Red
        case 4:
            return 'bg-purple-100 text-purple-900'; // Purple
        case 5:
            return 'bg-yellow-100 text-yellow-900'; // Green
        default:
            return 'bg-gray-100 text-gray-900'; // Gray
    }
}

export const getTimeAgoStatusColor = (date) => {
    const now = new Date();
    const then = new Date(date);

    if (then < now) {
        return 'bg-red-100 text-red-900'; // Red
    }

    if (then > now) {
        return 'bg-teal-100 text-teal-900'; // Teal
    }

    return 'bg-yellow-100 text-yellow-900'; // Yellow
}

export const handleFileChange = (e, item) => {
    const file = e.target.files?.length > 0 ? e.target.files[0] : null;
    item.file = file;
}

export const handleFilesChange = (e, item) => {
    const files = e.target.files?.length > 0 ? e.target.files : [];
    if (!item.files) {
        item.files = [];
    }

    for (let i = 0; i < files.length; i++) {
        item.files.push({
            file: files[i],
            name: files[i].name,
            size: files[i].size,
            type: files[i].type,
        });
    }
}

export const initials = (name) => {
    return name
        .split(' ')
        .map((part) => part[0].toUpperCase())
        .join('');
}

export const getVendorComponentElement = (vendor, component, el) => {
    if (!vendor || !component || !el || !vendor.components) {
        return [];
    }

    const c = vendor.components.find(x => x.id === component.id);
    if (!c || !c.elements) {
        return [];
    }

    const e = c.elements.find(x => x.id === el.id);
    if (!e || !e.values) {
        return [];
    }

    return e.values;
};

export const getVendorComponentStatus = (vendor, component) => {
    if (!vendor || !component || !vendor.componentStatus) {
        return null;
    }

    const c = vendor.componentStatus.find(x => x.packageComponentId === component.id);
    if (!c) {
        return null;
    }

    return c.status;
};

export const addRef = (id) => {
    const input = document.getElementById(id);
    if (input) {
        input.click();
    }
}