<script setup>

import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { icons } from '@/utils/icons';

const { t } = useI18n();

const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: '',
    },
    description: {
        type: String,
        default: '',
    },
    enableButton: {
        type: Boolean,
        default: true,
    },
})

const emits = defineEmits(['confirm', 'update:open']);

const isConfirming = ref(false);
const isOpen = ref(props.isOpen ?? false);

watch(() => props.isOpen, (value) => {
    isOpen.value = value;
});

const con = async () => {
    if (isConfirming.value) {
        return;
    }

    isConfirming.value = true;
    emits('confirm');
    isConfirming.value = false;
}

const changeOpen = (o) => {
    isOpen.value = o;
    emits('update:open', o);
}
</script>

<template>
    <AlertDialog :open="isOpen" @update:open="changeOpen">
        <AlertDialogContent>
            <AlertDialogHeader v-if="props.title || props.description">
                <AlertDialogTitle v-if=props.title>{{ props.title }}</AlertDialogTitle>
                <AlertDialogDescription v-if="props.description">
                    {{ props.description }}
                </AlertDialogDescription>
            </AlertDialogHeader>
            <Transition mode="out-in">
                <div v-if="$slots.content">
                    <slot name="content"></slot>
                </div>
            </Transition>
            <AlertDialogFooter>
                <AlertDialogCancel :disabled="isConfirming">{{ t('common.cancel') }}</AlertDialogCancel>
                <AlertDialogAction :disabled="isConfirming || !enableButton" @click="con">
                    <Transition mode="out-in">
                        <Icon :icon="icons.loaderCircle" v-if="isConfirming" class="w-4 h-4 shrink-0" />
                        <span v-else>{{ t('common.confirm') }}</span>
                    </Transition>
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>