<script setup>

import { ref } from 'vue';
import { useAgentState } from '@/stores/AgentState';

import { form } from '@/utils/http';

const agent = useAgentState();

import UI from '../UI.vue';

const emits = defineEmits(['posting', 'update:rfx']);

const props = defineProps({
    url:{
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: null,
    },
    buttonText: {
        type: String,
        default: null,
    },
    w: {
        type: String,
        default: 'sm:w-[850px]',
    },
});

const isPosting = ref(false);

const rfx = async (sources) => {
    if (!sources.instructions) {
        return;
    }

    if (isPosting.value) {
        return;
    }

    emits('posting', true);

    isPosting.value = true;

    const { data } = await form(`/global/agents/lumi/${props.url}`, {
        payload: {
            id: sources.id,
            files: sources.files,
            links: sources.links,
            instructions: sources.instructions,
            references: sources.references,
        },
    });

    isPosting.value = false;

    agent.setLumi(data);

    emits('posting', false);
    emits('update:rfx', data);
}

</script>

<template>
    <UI :w="w" :placeholder="placeholder" :buttonText="buttonText" :isPosting="isPosting" @update:click="rfx">
        <template v-if="$slots.upper" #upperC>
            <slot name="upper">
            </slot>
        </template>
    </UI>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.10s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>