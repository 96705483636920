<script setup>

import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { useGlobalState } from '@/stores/GlobalState';
import { v4 as uuidv4 } from 'uuid';

import { onBeforeEnter, onEnter, onLeave } from '@/utils/animations';

import { icons } from '@/utils/icons';
import RFx from '../../Agents/Lumi/RFx.vue';
import ElementSettings from './Settings/ElementSettings.vue';

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    componenet: Object,
});

const emits = defineEmits(['update:edit', 'update:save']);

const copyPerScheme = ref({
    0: null,
    1: null,
    scheme: null,
    drag: false,
})

const edit = (c, scheme) => {
    if (copyPerScheme.value[scheme]) {

        copyPerScheme.value[scheme] = null;
        copyPerScheme.value.scheme = null;
        copyPerScheme.value.drag = false;

        return;
    }

    copyPerScheme.value[scheme] = JSON.parse(JSON.stringify(c));
    copyPerScheme.value.scheme = scheme;
    copyPerScheme.value.drag = false;

    if (props.componenet) {
        props.componenet.hasAssignedUser = false;
    }
};

const saveCopy = () => {
    emits('update:save', { ...copyPerScheme.value[copyPerScheme.value.scheme] });

    copyPerScheme.value[copyPerScheme.value.scheme] = null;
    copyPerScheme.value.scheme = null;
    copyPerScheme.value.drag = false;

    if (props.componenet) {
        props.componenet.hasAssignedUser = false;
    }
};

const removeElement = (index) => {
    props.componenet.elements.splice(index, 1);
};

const duplicateElement = (index) => {
    const copy = JSON.parse(JSON.stringify(props.componenet.elements[index]));

    copy.uid = uuidv4();
    props.componenet.elements.splice(index, 0, copy);
};

const updateRfx = (data) => {
    const e = data.component.elements.map(x => ({
        ...x,
        uid: uuidv4(),
        scheme: copyPerScheme.value.scheme,
    }));

    copyPerScheme.value[copyPerScheme.value.scheme].elements = copyPerScheme.value[copyPerScheme.value.scheme].elements.concat(e);
    copyPerScheme.value.drag = true;
}


</script>

<template>
    <div class="flex flex-col space-y-2 justify-top w-full">

        <Transition mode="out-in">
            <Card v-if="(copyPerScheme[0] || copyPerScheme[1]) && copyPerScheme.drag" class="flex flex-col p-3">

                <ComponentInput :item="copyPerScheme[0] ?? copyPerScheme[1]" :scheme="copyPerScheme.scheme" :enableInput="false" :enableCopy="false" :enableRemove="false" @update:edit="v => {
                    emits('update:edit', v);
                    if (!v) {
                        copyPerScheme[0] = null;
                        copyPerScheme[1] = null;
                        copyPerScheme.drag = false;
                        copyPerScheme.scheme = null;
                    }
                }" @update:save="saveCopy">
                    <template #controls>
                        <div class="flex flex-row items-center justify-between mt-4 w-full">

                            <Button variant="secondary" @click="() => {
                                copyPerScheme[0] = null;
                                copyPerScheme[1] = null;
                                copyPerScheme.drag = false;
                                copyPerScheme.scheme = null;
                            }">
                                {{ t('common.cancel') }}
                            </Button>

                            <Button variant="default" @click="saveCopy">
                                {{ t('common.save') }}
                            </Button>

                        </div>
                    </template>
                </ComponentInput>

            </Card>
            <div v-else class="flex flex-col space-y-2 justify-top w-full">
                <Transition mode="out-in">
                    <Card v-if="props.componenet" class="p-3 flex flex-col gap-2">
                        <div class="flex flex-row items-center justify-between gap-1">

                            <div class="space-y-1 leading-none flex flex-row w-full justify-between items-center">
                                <div class="text-muted-foreground text-xs">{{ t('common.requirementBuyerInputDescription') }}</div>
                            </div>

                            <div class="flex flex-row items-center">

                                <Transition mode="out-in">

                                    <div v-if="props.componenet.elements.filter(x => x.scheme === 0).length > 0" class="flex flex-row items-center gap-2">
                                        <Tooltip>
                                            <TooltipTrigger as-child>
                                                <Button :variant="props.componenet.hasAssignedUser ? 'default' : 'ghost'" size="sm" @click="() => { props.componenet.hasAssignedUser = !props.componenet.hasAssignedUser; !props.componenet.hasAssignedUser ? props.componenet.ownerId = null : props.componenet.ownerId = props.componenet.ownerId }">
                                                    <Icon :icon="icons.user" />
                                                    <span class="sr-only">
                                                        {{ t('common.assignToUser') }}
                                                    </span>
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                {{ t('common.assignToUser') }}
                                            </TooltipContent>
                                        </Tooltip>
                                        <div class="flex flex-row items-center gap-1">
                                            <Transition mode="out-in">
                                                <Dropdown v-if="props.componenet.hasAssignedUser || !!props.componenet.ownerId" :items="store.types.users" :title="t('common.person')" :icon="icons.user" :modelValue="props.componenet.ownerId" @update:modelValue="v => props.componenet.ownerId = v">
                                                    <template #individual>
                                                        <div class="flex flex-col items-center mb-1">
                                                            <Button @click="createUser(props.componenet, null)" class="w-full" size="sm" variant="secondary">
                                                                {{ t('common.create') }} {{ t('common.new') }}
                                                                <Icon :icon="icons.plus" class="ml-2" />
                                                            </Button>
                                                        </div>
                                                    </template>
                                                </Dropdown>
                                            </Transition>
                                            <Transition mode="out-in">
                                                <Button v-if="props.componenet.ownerId" @click="props.componenet.ownerId = null" variant="secondary" size="sm" class="shrink-0">
                                                    <Icon :icon="icons.xxx" class="shrink-0" />
                                                </Button>
                                            </Transition>
                                        </div>
                                    </div>

                                </Transition>

                                <Tooltip>
                                    <TooltipTrigger>
                                        <Button size="sm" class="shrink-0" variant="ghost" @click="edit(props.componenet, 0)">
                                            <Transition mode="out-in">
                                                <Icon v-if="copyPerScheme[0]" :icon="icons.redo" class="shrink-0" />
                                                <Icon v-else :icon="icons.edit" class="shrink-0" />
                                            </Transition>
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {{ t('common.edit') }}
                                    </TooltipContent>
                                </Tooltip>
                            </div>

                        </div>

                        <Transition mode="out-in">

                            <div v-if="copyPerScheme[0]" class="flex flex-col gap-4">
                                <div class="flex flex-row items-center gap-1">
                                    <Transition mode="out-in">
                                        <div v-if="props.componenet.elements.filter(x => x.scheme === 0).length > 0" class="flex flex-row items-center gap-1">
                                            <Badge v-for="(e, index) in props.componenet.elements.filter(x => x.scheme === 0)" :key="index" variant="secondary" class="w-fit">
                                                {{ e.name }}
                                            </Badge>
                                        </div>
                                    </Transition>
                                    <Button size="sm" variant="secondary" @click="() => copyPerScheme.drag = !copyPerScheme.drag">
                                        <Icon :icon="icons.plus" />
                                    </Button>
                                </div>
                                <RFx url="rfx-single" @update:rfx="updateRfx" :placeholder="t('common.describeAddtionalInputs')" :buttonText="t('common.add')" w="w-full">
                                    <template #upper>
                                        <Button size="sm" variant="outline" class="text-destructive" @click="copyPerScheme[0] = null">
                                            <Icon :icon="icons.xxx" />
                                        </Button>
                                    </template>
                                </RFx>
                            </div>
                            <div v-else>
                                <div v-if="props.componenet.elements.filter(x => x.scheme === 0).length > 0" class="w-full space-y-3">

                                    <TransitionGroup name="list" :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
                                        <div v-for="(e, eIndex) in props.componenet.elements.filter(x => x.scheme === 0)" :key="eIndex" class="flex flex-col">
                                            <div class="flex flex-col w-full gap-1.5">
                                                <ElementValue :element="e">

                                                    <template #label>

                                                        <ElementSettings :item="e" :componenet="props.componenet" @update:remove="removeElement(eIndex)" @update:duplicate="duplicateElement(eIndex)" />

                                                    </template>

                                                </ElementValue>

                                                <Transition mode="out-in">
                                                    <Badge v-if="e.ownerId" class="text-muted-foreground text-xs flex flex-row items-center gap-1 w-fit" variant="secondary">
                                                        <span>
                                                            {{ t('common.assignedTo') }}
                                                        </span>
                                                        <span>
                                                            {{ store.tv('users', e.ownerId) }}
                                                        </span>
                                                        <Icon :icon="icons.xxx" class="hover:cursor-pointer text-destructive shrink-0" @click="e.ownerId = null" />
                                                    </Badge>
                                                </Transition>

                                            </div>
                                        </div>
                                    </TransitionGroup>

                                </div>
                            </div>
                        </Transition>

                    </Card>
                </Transition>


                <Transition mode="out-in">

                    <Card v-if="props.componenet" class="flex flex-col items-center gap-4 space-y-0 rounded-md border-dashed p-4 grow shrink-0">

                        <div class="flex flex-row items-center justify-between gap-1 w-full">

                            <div class="space-y-1 leading-none flex flex-row w-full justify-between items-center">
                                <div class="flex flex-col gap-4 grow">
                                    <div class="text-muted-foreground text-xs">{{ t('common.requirementVendorInputDescription') }}</div>
                                    <div class="flex flex-row items-center gap-1">
                                        <Transition mode="out-in">
                                            <div v-if="props.componenet.elements.filter(x => x.scheme === 1).length > 0" class="flex flex-row items-center gap-1">
                                                <Badge v-for="(e, index) in props.componenet.elements.filter(x => x.scheme === 1)" :key="index" variant="secondary" class="w-fit">
                                                    {{ e.name }}
                                                </Badge>
                                            </div>
                                        </Transition>
                                        <Transition mode="out-in">
                                            <Button v-if="copyPerScheme[1]" size="sm" variant="secondary" @click="copyPerScheme.drag = !copyPerScheme.drag">
                                                <Icon :icon="icons.plus" />
                                            </Button>
                                        </Transition>
                                    </div>
                                </div>
                            </div>

                            <Tooltip>
                                <TooltipTrigger>
                                    <Button size="sm" class="shrink-0" variant="ghost" @click="edit(props.componenet, 1)">
                                        <Transition mode="out-in">
                                            <Icon v-if="copyPerScheme[1]" :icon="icons.redo" class="shrink-0" />
                                            <Icon v-else :icon="icons.edit" class="shrink-0" />
                                        </Transition>
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {{ t('common.edit') }}
                                </TooltipContent>
                            </Tooltip>

                        </div>

                        <Transition mode="out-in">
                            <div v-if="copyPerScheme[1]" class="flex flex-col gap-4 w-full">
                                <RFx url="rfx-single" @update:rfx="updateRfx" :placeholder="t('common.describeAddtionalInputs')" :buttonText="t('common.add')" w="w-full">
                                    <template #upper>
                                        <Button size="sm" variant="outline" class="text-destructive" @click="copyPerScheme[1] = null">
                                            <Icon :icon="icons.xxx" />
                                        </Button>
                                    </template>
                                </RFx>
                            </div>
                        </Transition>

                    </Card>

                </Transition>
            </div>
        </Transition>

    </div>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>