import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useVersionStore = defineStore('version', () => {
    const current = ref(null)

    function checkVersion() {

        fetch(`${location.origin}/assets/settings/stamp.txt`)
            .then(response => response.text())
            .then(data => {
                const trimmed = data.trim();

                if (trimmed) {
                    setVersion(trimmed);
                }

                return trimmed;
            })
    }

    function setVersion(v) {
        const existing = localStorage.getItem(`--latestversion`);
        if (existing === v) {
            return;
        }

        current.value = v;
        localStorage.setItem(`--latestversion`, v)
    }

    return {
        checkVersion,
        current,
    }
})