<script setup>

import { ref, computed, onMounted, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid';

import { useGlobalState } from '@/stores/GlobalState';

import { isValidElements } from '@/views/buyers/templates/packs/helpers/packUtils';

import { icons } from '@/utils/icons';
import { useDraggable } from 'vue-draggable-plus'
import ElementSettings from './Settings/ElementSettings.vue';

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    item: {},
    index: {},
    enableCopy: {
        default: true,
        type: Boolean
    },
    enableInput: {
        default: true,
        type: Boolean
    },
    enableRemove: {
        default: true,
        type: Boolean
    },
    scheme: {}
});

const emits = defineEmits(['copy', 'remove', 'update:edit', 'update:save']);
const isEditing = ref(false);
const editScheme = ref(props.scheme);
const itemCopy = ref(null);

onMounted(() => {
    props.item.elements.forEach(element => {
        element.values = !element.values || element.values.length === 0 ? [{ uid: uuidv4(), value: null }] : element.values;
    });

    if (props.scheme !== null && props.scheme !== undefined) {
        editScheme.value = props.scheme;
        edit(props.scheme);
    }
});

watch(() => props.item, (newVal, _) => {
    if (!newVal) {
        return;
    }

    newVal.elements.forEach(element => {
        element.values = !element.values || element.values.length === 0 ? [{ uid: uuidv4(), value: null }] : element.values;
    });
});

watch(() => props.scheme, (newVal, _) => {
    if (!newVal) {
        return;
    }

    editScheme.value = newVal;
});

const edit = (scheme) => {
    isEditing.value = !isEditing.value;

    emits('update:edit', isEditing.value);

    if (!isEditing.value) {
        cancelEdit();
    } else {
        itemCopy.value = createCopy();
        editScheme.value = scheme;
        form.value = itemCopy.value.elements.filter(x => x.scheme === scheme);
    }
}

const cancelEdit = () => {

    isEditing.value = false;
    itemCopy.value = null;
    editScheme.value = null;
    form.value = [];

    emits('update:edit', isEditing.value);
}

const saveEdit = async () => {

    props.item.elements = itemCopy.value.elements;

    await nextTick();

    itemCopy.value = null;
    isEditing.value = false;

    emits('update:save');
}

const copy = () => {
    const copy = createCopy();
    emits('copy', { copy, index: props.index });
}

const createCopy = () => {
    const copy = JSON.parse(JSON.stringify(props.item));
    copy.uid = uuidv4();

    copy.elements.forEach(element => {
        element.values = element.values.map(v => ({ ...v, uid: uuidv4() }));
    });

    return copy;
}

const inputs = computed(() => [...store.enums.ElementType].sort((a, b) => {
    if (a.value >= 9 && b.value < 9) {
        return -1;
    } else if (a.value < 9 && b.value >= 9) {
        return 1;
    } else {
        return a.value - b.value;
    }
}).map(x => ({
    uid: uuidv4(),
    type: x.value,
    name: x.label,
    inputCount: 0,
    scheme: editScheme.value,
    responseLimit: null,
    values: [{ uid: uuidv4(), value: null }],
})))

const inputsRef = ref();
const formRef = ref();
const trashRef = ref();

const form = ref([]);
const trash = ref([]);

watch([inputsRef, formRef, trashRef], ([iRef, fRef, tRef]) => {
    if (iRef && fRef && tRef) {

        useDraggable(iRef, inputs, {
            animation: 150,
            group: { name: 'zero', pull: 'clone', put: false },
            sort: false,
            clone: (original) => {
                return { ...original };
            }
        });

        useDraggable(tRef, trash, {
            animation: 150,
            group: { name: 'trash', pull: false, put: () => true },
            sort: false,
            onAdd: () => {
                trash.value = [];
            }
        });

        useDraggable(fRef, form, {
            animation: 150,
            group: 'zero',
        });
    }
})

watch([form], ([zero]) => {
    if (itemCopy.value) {
        itemCopy.value.elements = [...zero, ...itemCopy.value.elements.filter(x => x.scheme !== editScheme.value)]
    }
}, { deep: true });


</script>

<template>

    <Transition mode="out-in">

        <div v-if="item" class="w-full px-3 py-2 space-y-2">
            <div class="flex flex-row items-center justify-between font-semibold">
                <span>
                    {{ props.item.name }}
                </span>
                <div class="flex flex-row items-center gap-1">
                    <Tooltip v-if="enableCopy">
                        <TooltipTrigger>
                            <Button variant="outline" @click="copy" size="sm">
                                <Icon :icon="icons.copy" class="shrink-0" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ t('common.copy') }}
                        </TooltipContent>
                    </Tooltip>
                    <ConfirmClose v-if="enableRemove" @close="emits('remove', { item: props.item, index: props.index })" />
                    <div class="flex flex-row items-center gap-1">

                        <Button variant="secondary" @click="cancelEdit()">
                            {{ t('common.cancel') }}
                        </Button>

                        <Button variant="default" :disabled="!isValidElements(itemCopy?.elements)" @click="saveEdit()">
                            {{ t('common.save') }}
                        </Button>
                    </div>
                </div>
            </div>

            <Transition mode="out-in">

                <div v-if="isEditing" class="py-2">

                    <div class="flex flex-row gap-2">

                        <div class="flex flex-col w-1/6 shrink-0 my-2.5">

                            <span class="text-center">{{ t('common.inputs') }}</span>

                            <div ref="trashRef">
                                <div ref="inputsRef" class="flex flex-col shrink-0 p-4 space-y-2.5">
                                    <Button v-for="(item, index) in inputs" :key="item.uid" class="cursor-grab p-3" variant="outline">
                                        {{ item.name }}
                                    </Button>
                                </div>
                            </div>
                        </div>


                        <div class="flex flex-col gap-2 overflow-auto border rounded-md border-dashed w-full p-4">
                            <div class="font-medium text-center mb-2">{{ editScheme === 0 ? t('common.requirementBuyerInputDescription') : t('common.requirementVendorInputDescription') }}</div>
                            <span class="text-center">{{ t('common.dragAndDropInputs') }}</span>

                            <div ref="formRef" class="h-full space-y-2">
                                <div v-for="(item, index) in form" :key="item.uid" class="flex flex-row gap-1">
                                    <ElementValue :element="item" :readonly="true">

                                        <template #label>

                                            <ElementSettings :item="item" @update:remove="form.splice(index, 1)" />

                                        </template>

                                    </ElementValue>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-else>
                    <Transition mode="out-in">
                        <div v-if="props.enableInput" class="flex flex-col w-full">

                            <ElementForm :items="props.item.elements.filter(x => x.scheme === 0)" />

                        </div>
                    </Transition>
                    <div class="flex flex-col items-start gap-2 w-full">
                        <Transition mode="out-in">
                            <slot v-if="$slots.controls" name="controls"></slot>
                        </Transition>
                    </div>
                </div>
            </Transition>


        </div>
    </Transition>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>