import { createApp } from 'vue'
import { createPinia } from 'pinia'

import router from './router';

import { importComponents } from './imports';

import './style.css'
import App from './App.vue'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import es from 'javascript-time-ago/locale/es'

import { createI18n } from 'vue-i18n';
import { messages } from './localization/messages.js';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(es);

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages,
  });

const pinia = createPinia();
const app = createApp(App)

importComponents(app);

app.use(pinia);
app.use(router);
app.use(i18n);

app.directive('focus', {
    mounted(el) {
        el.focus()
    }
})

app.config.errorHandler = (err) => {
    console.error(err);
}

app.mount('#app');
