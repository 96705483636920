<script setup>

import { ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n'
import { useGlobalState } from '@/stores/GlobalState';
import { useRouter } from 'vue-router';

import { icons } from '@/utils/icons';
import ProjectForm from '@/views/_shared/Components/Forms/ProjectForm.vue';
import POForm from '@/views/_shared/Components/Forms/POForm.vue';

const { t } = useI18n();
const store = useGlobalState();
const router = useRouter();

const props = defineProps({
    title: {
        type: String,
        Required: true
    },
    templateId: {
        type: Number,
        default: null
    },
    projectId: {
        type: Number,
        default: null
    },
    variant: {
        type: String,
        default: 'default'
    },
    isOpen: {},
    settings: {
        type: Object,
        default: {
            showProject: true,
            showTemplate: true,
            showPO: true
        }
    }
});

const isSelectorOpen = ref(props.isOpen ?? false);

const handleSelectorOpen = (value) => {
    isSelectorOpen.value = value;
}

const selectTemplate = (e) => {
    const value = e.value;
    const template = store.lookups.templates.find(t => t.id === value);
    const tId = template?.id;
    isSelectorOpen.value = false;

    router.push({ name: 'buyer-create-package', params: { templateId: tId, projectId: props.projectId } });
}

const createTemplate = () => {
    isSelectorOpen.value = false;

    router.push({ name: 'buyer-create-package', params: { templateId: null, projectId: props.projectId } });
}

const isProjectModalOpen = ref(false);
const projectITem = ref(null);

const createProject = () => {
    projectITem.value = {};
    isProjectModalOpen.value = true;
    isSelectorOpen.value = false;
}

const closeProjectModal = () => {
    isProjectModalOpen.value = false;
    projectITem.value = {};
}

const addNewProject = (v) => {
    if (!store.lookups.projects.find(c => c.id === v.id)) {
        store.lookups.projects.push(v);
    }

    closeProjectModal();
    router.push({ name: 'buyer-manage-project', params: { id: v.id } });
}

const isPOModalOpen = ref(false);
const poItem = ref(null);

const createPO = () => {
    poItem.value = {};
    isPOModalOpen.value = true;
    isSelectorOpen.value = false;
}

const addNewPO = () => {
    closePOModal();
    router.push({ name: 'purchase-orders' });
}

const closePOModal = () => {
    isPOModalOpen.value = false;
    poItem.value = {};
}

watchEffect(() => {
    if (props.isOpen === false) {
        handleSelectorOpen(false);
    }
});

</script>

<template>
    <Tooltip>
        <TooltipTrigger>
            <Button @click="() => handleSelectorOpen(true)" size="sm" :variant="props.variant" class="flex flex-row items-center gap-1">
                {{ props.title }}
                <Icon :icon="icons.plus" class="w-4 h-4 shrink-0" />
            </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
            {{ t('common.create') }}
        </TooltipContent>
    </Tooltip>

    <CommandDialog :open="isSelectorOpen" @update:open="o => handleSelectorOpen(o)">
        <Transition mode="out-in">
            <CommandInput v-if="settings?.showTemplate && store.types.templates.length > 0" :placeholder="t('common.typeACommandOrSearch')" />
        </Transition>
        <CommandList>
            <CommandEmpty>
                <div class="flex flex-col gap-2">
                    <span>
                        {{ t('common.noResultsFound') }}
                    </span>
                    <span>
                        <Button class="border-dashed" @click="selectTemplate({ detail: { value: null } })">
                            {{ t('common.createFromScratch') }}
                        </Button>
                    </span>
                </div>
            </CommandEmpty>
            <template v-if="$slots.top">
                <slot name="top"></slot>
            </template>
            <Transition mode="out-in">
                <CommandGroup :heading="t('common.select')">
                    <CommandItem v-if="settings?.showPO" class="hover:cursor-pointer" @select="createPO" :key="-1" value="Purchase Order">
                        <Icon :icon="icons.purchaseOrder" class="mr-2 h-4 w-4 shrink-0" />
                        {{ t('common.new') }} {{ t('common.purchaseOrder') }}
                    </CommandItem>
                    <CommandItem v-if="settings?.showProject" class="hover:cursor-pointer" @select="createProject" :key="-1" value="Project">
                        <Icon :icon="icons.project" class="mr-2 h-4 w-4 shrink-0" />
                        {{ t('common.new') }} {{ t('common.project') }}
                    </CommandItem>
                    <CommandItem class="hover:cursor-pointer" @select="createTemplate" :key="-2" value="Rfx">
                        <Icon :icon="icons.package" class="mr-2 h-4 w-4 shrink-0" />
                        {{ t('common.new') }} {{ t('common.package') }}
                    </CommandItem>
                </CommandGroup>
            </Transition>
            <Transition mode="out-in">
                <CommandGroup v-if="settings?.showTemplate" :heading="t('common.pickTemplate')">
                    <CommandItem v-for="(item, index) in store.types.templates" :key="index" :value="item.label" class="hover:cursor-pointer" @select="selectTemplate(item)">
                        <Icon :icon="icons.package" class="mr-2 h-4 w-4 shrink-0" />
                        {{ item.label }}
                    </CommandItem>
                </CommandGroup>
            </Transition>
            <template v-if="$slots.bottom">
                <slot name="bottom"></slot>
            </template>
        </CommandList>
    </CommandDialog>

    <Modal :open="isProjectModalOpen" @update:open="v => isProjectModalOpen = v">

        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <div v-if="projectITem" class="flex flex-col grow">
                    <ProjectForm :item="projectITem" @close="closeProjectModal" @update:modelValue="v => addNewProject(v.new)" />
                </div>
            </div>
        </template>

    </Modal>

    <Modal :open="isPOModalOpen" @update:open="v => isPOModalOpen = v">

        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <div v-if="poItem" class="flex flex-col grow">
                    <POForm :item="poItem" @close="closePOModal" @update:modelValue="v => addNewPO(v.new)" />
                </div>
            </div>
        </template>

    </Modal>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>