import { getCurrentSubscription } from '@/utils/auth';

const vendorPrefix = "/hi";

export const usersUrl = () => getUrl('/global/users');

export const sourcesUrl = () => getUrl('/global/refs');

export const lookupsUrl = () =>  getUrl('/global/lookups');

export const searchDocUrl = () => getUrl('/global/ai/document');

export const getUrl = (url) => {
    if (getCurrentSubscription().isBuyer) {
        return url;
    }

    return `${vendorPrefix}${url}`;
}

