<script setup>

import { ref, watch, computed, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n'

import { useForm } from 'vee-validate'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import { post, put } from '@/utils/http';
import { useGlobalState } from '@/stores/GlobalState';
import { icons } from '@/utils/icons';
import { usersUrl } from '@/utils/urlResolver';
import { toast } from 'vue-sonner';

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
});

const emits = defineEmits(['update:modelValue', 'close']);
const isPosting = ref(false);
const url = computed(() => usersUrl());

const formSchema = toTypedSchema(z.object({
    id: z.number().optional().nullable().default(null),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    phone: z.string().optional().nullable().default(null),
    isAdmin: z.boolean().default(false),
}))

const { handleSubmit, setValues, resetForm, meta, values } = useForm({
    validationSchema: formSchema,
})

watch(() => props.item, (value) => {
    if (!value) {
        setValues({});
        resetForm();
        return;
    }

    setValues({
        ...value,
    });
}, { immediate: true });

const onSubmit = handleSubmit(async (values, { resetForm }) => {
    try {
        if (!meta.value.valid) {
            return;
        }
    
        if (isPosting.value) {
            return;
        }
    
        isPosting.value = true;
    
        const data = await send();
        store.refershLookups();
    
        isPosting.value = false;
    
        emits('update:modelValue', {
            old: props.item,
            new: data
        });
    
        setValues({});
        resetForm();
    } catch (e) {
        isPosting.value = false;
        
        toast(t('common.failedToSave'), {
            description: '',
            action: {
                label: t('common.close'),
            },
        })
    }
})

const send = async () => {
    if (props.item.id) {
        const { data } = await put(url.value, {
            ...values,
        });
        return data;
    } else {
        const { data } = await post(url.value, {
            ...values,
        });
        return data;
    }
}

onMounted(() => {
    document.addEventListener('keydown', close);
    document.addEventListener('keydown', save);
});

onUnmounted(() => {
    setValues({});
    resetForm();

    document.removeEventListener('keydown', close);
    document.removeEventListener('keydown', save);
});

const close = (e) => {
    if (isPosting.value) {
        return;
    }

    if (e.key === 'Escape') {
        emits('close');
    }
}

const save = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
        onSubmit();
    }
}

</script>

<template>

    <div class="flex flex-col justify-center sm:items-center">
        <Card class="w-full p-4">

            <CardHeader>
                <CardTitle>
                    <div class="flex flex-row items-center justify-between">
                        <span class="flex flex-row items-center gap-2">
                            <Icon :icon="icons.user" class="shrink-0" />
                            <div class="font-medium">{{ props.item?.id ? t('common.edit') : t('common.create') }}</div>
                        </span>
                        <ConfirmClose :isDisabled="isPosting" @close="emits('close')" />
                    </div>
                </CardTitle>
            </CardHeader>

            <CardContent class="flex flex-col w-full">

                <div class="flex flex-row items-center justify-between gap-4">

                    <div class="w-1/2">
                        <FormField v-slot="{ componentField }" name="firstName">
                            <FormItem v-auto-animate>
                                <FormControl>
                                    <Input type="text" :placeholder="t('common.firstName')" v-bind="componentField" />
                                </FormControl>
                                <FormDescription>
                                </FormDescription>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="w-1/2">
                        <FormField v-slot="{ componentField }" name="lastName">
                            <FormItem v-auto-animate>
                                <FormControl>
                                    <Input type="text" :placeholder="t('common.lastName')" v-bind="componentField" />
                                </FormControl>
                                <FormDescription>
                                </FormDescription>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>

                </div>

                <div class="flex flex-row items-center justify-between gap-4">

                    <div class="w-1/2">
                        <FormField v-slot="{ componentField }" name="email">
                            <FormItem v-auto-animate>
                                <FormControl>
                                    <Input type="email" :placeholder="t('common.email')" v-bind="componentField" />
                                </FormControl>
                                <FormDescription>
                                </FormDescription>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="w-1/2">
                        <FormField v-slot="{ componentField }" name="phone">
                            <FormItem v-auto-animate>
                                <FormControl>
                                    <Input type="text" :placeholder="t('common.phone')" v-bind="componentField" />
                                </FormControl>
                                <FormDescription>
                                </FormDescription>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>

                </div>

                <div class="mt-3">

                    <CheckCard :isChecked="values.isAdmin" @update:modelValue="v => setValues({ isAdmin: v })" :title="t('common.adminTitle')" :description="t('common.adminDescription')" :checkIcon="icons.admin" :unCheckIcon="icons.user" />

                </div>


            </CardContent>

            <CardFooter class="flexflex-row px-6 pb-6 gap-2 justify-end">
                <ProcessingButton :disabled="isPosting || !meta.valid" @click="onSubmit" :processing="isPosting" variant="default" :text="props.item?.id ? t('common.edit') : t('common.save')" />
            </CardFooter>
        </Card>
    </div>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>