<script setup>

import { ref, watch, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n'

import { useForm } from 'vee-validate'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import { figurePostPutOut } from '@/utils/http';
import { icons } from '@/utils/icons';
import { useGlobalState } from '@/stores/GlobalState';
import { toast } from 'vue-sonner';

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
});

const emits = defineEmits(['update:modelValue', 'close']);
const isPosting = ref(false);

const formSchema = toTypedSchema(z.object({
    id: z.number().optional().nullable().default(null),
    name: z.string().min(1),
    referenceNumber: z.string().optional().nullable().default(null),
    description: z.string().optional().nullable().default(null),
    targetDate: z.string().optional().nullable().default(null),
}))

const { handleSubmit, setValues, resetForm, meta, values } = useForm({
    validationSchema: formSchema,
})

watch(() => props.item, (value) => {
    if (!value) {
        setValues({});
        resetForm();
        return;
    }

    setValues({
        ...value,
    });
}, { immediate: true });

const onSubmit = handleSubmit(async (values, { resetForm }) => {
    try {
        if (!meta.value.valid) {
            return;
        }

        if (isPosting.value) {
            return;
        }

        isPosting.value = true;

        const { data } = await figurePostPutOut('/procurement/projects', {
            id: props.item.id,
            payload: {
                ...values,
            }
        });

        store.refershLookups();

        isPosting.value = false;
        emits('update:modelValue', {
            old: props.item,
            new: data
        });

        setValues({});
        resetForm();
    } catch (e) {
        isPosting.value = false;

        toast(t('common.failedToSave'), {
            description: '',
            action: {
                label: t('common.close'),
            },
        })
    }

});

onMounted(() => {
    document.addEventListener('keydown', close);
    document.addEventListener('keydown', save);
});

onUnmounted(() => {
    setValues({});
    resetForm();

    document.removeEventListener('keydown', close);
    document.removeEventListener('keydown', save);
});

const close = (e) => {
    if (isPosting.value) {
        return;
    }

    if (e.key === 'Escape') {
        emits('close');
    }
}

const save = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
        onSubmit();
    }
}

</script>

<template>

    <div class="flex flex-col justify-center sm:items-center">
        <Card class="w-full p-4">

            <CardHeader>
                <CardTitle>
                    <div class="flex flex-row items-center justify-between">
                        <span class="flex flex-row items-center gap-2">
                            <Icon :icon="icons.project" class="shrink-0" />
                            <div class="font-medium">{{ props.item?.id ? t('common.edit') : t('common.create') }}</div>
                        </span>
                        <ConfirmClose :isDisabled="isPosting" @close="emits('close')" />
                    </div>
                </CardTitle>
            </CardHeader>

            <CardContent class="flex flex-col w-full">

                <div class="flex flex-row items-center justify-between gap-4">

                    <div class="w-1/3">
                        <FormField v-slot="{ componentField }" name="name">
                            <FormItem v-auto-animate>
                                <FormControl>
                                    <Input type="text" :placeholder="t('common.name')" v-bind="componentField" />
                                </FormControl>
                                <FormDescription>
                                </FormDescription>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>

                    <div class="w-1/3">
                        <FormField v-slot="{ componentField }" name="referenceNumber">
                            <FormItem v-auto-animate>
                                <FormControl>
                                    <Input type="text" :placeholder="t('common.uniqueRef')" v-bind="componentField" />
                                </FormControl>
                                <FormDescription>
                                </FormDescription>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>

                    <div class="w-1/3 mb-2">
                        <DatePicker :modelValue="values.targetDate" :title="t('common.targetDate')" @update:modelValue="v => setValues({ targetDate: v })" class="w-full" />
                    </div>
                </div>

                <div class="mt-2">
                    <FormField v-slot="{ componentField }" name="description">
                        <FormItem v-auto-animate>
                            <FormControl>
                                <Textarea :placeholder="t('common.description')" v-bind="componentField"></Textarea>
                            </FormControl>
                            <FormDescription>
                            </FormDescription>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>

            </CardContent>
            <CardFooter class="flexflex-row px-6 pb-6 gap-2 justify-end">
                <ProcessingButton :disabled="isPosting || !meta.valid" @click="onSubmit" :processing="isPosting" variant="default" :text="props.item?.id ? t('common.edit') : t('common.save')" />
            </CardFooter>
        </Card>
    </div>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>